import type { ReactElement } from "react";

interface IButtonGroup {
  stack: "vertically" | "horizontally";
  spacing: 2 | 4 | 6 | 8 | 10 | 12 | 16 | 20 | 24 | 32 | 36 | 40 | 44 | 48;
  children: ReactElement[];
}

export default function ButtonGroup({ stack, spacing, children }: IButtonGroup): ReactElement {
  if (stack === "vertically") {
    return (
      <div
        className="grid"
        style={{
          gridRowGap: spacing,
        }}
      >
        {children}
      </div>
    );
  }

  return (
    <div
      className="grid auto-cols-max grid-flow-col"
      style={{
        gridColumnGap: spacing,
      }}
    >
      {children}
    </div>
  );
}
