/* eslint-disable @typescript-eslint/no-empty-interface */
import classNames from "components/ui/classNames";
import isDarkModeSupportedPage from "components/ux/isDarkModeSupportedPage";
import type { LinkProps } from "next/link";
import Link from "next/link";
import { useRouter } from "next/router";
import { useQueryState } from "nuqs";
import type { ReactNode } from "react";

interface NavbarLinkProps extends LinkProps {
  href: string;
  children: ReactNode;
  className?: string;
}
export default function NavbarLink({ href, children, className, ...props }: NavbarLinkProps) {
  const router = useRouter();
  const isActive = router.pathname.includes(href);
  const [theme] = useQueryState("theme");
  // eslint-disable-next-line no-underscore-dangle
  const _href = theme !== null && isDarkModeSupportedPage(href) ? `${href}?theme=${theme}` : href;

  return (
    <Link
      href={_href}
      passHref={true}
      className={classNames(
        "inline-flex items-center border-transparent p-4 font-medium",
        "whitespace-nowrap text-sm tracking-wide",
        "lg:mt-1 lg:border-b-2 lg:p-0 lg:pb-1",
        "hover:border-gray-400 hover:text-gray-400 dark:hover:border-white dark:hover:text-white",
        "text-gray-600 dark:text-slate-300",
        {
          "rounded-md border-indigo-600 bg-violet-50 text-indigo-600 dark:border-orange-300 dark:text-orange-300 lg:rounded-none lg:bg-transparent":
            isActive,
        },
        className,
      )}
      {...props}
    >
      {children}
    </Link>
  );
}
