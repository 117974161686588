import Link from "next/link";
import * as React from "react";
import type {
  AlsoRepliedToCommentActivity,
  ReactedToCommentActivity,
  ReactedToReplyActivity,
  RepliedToCommentActivity,
} from "../../types";

interface ILinkToCommentProps extends React.HTMLAttributes<HTMLAnchorElement> {
  activity: RepliedToCommentActivity | AlsoRepliedToCommentActivity | ReactedToCommentActivity | ReactedToReplyActivity;
  children: any;
}

export default function LinkToComment({ activity, children, ...props }: ILinkToCommentProps) {
  if (
    activity &&
    activity.object &&
    typeof activity.object === "object" &&
    activity.object.data &&
    typeof activity.object.data === "object"
  ) {
    let linkHref = `/comments/${activity.object.data.shortId}`;

    // If it's a reference to a reply, the link has to be built differently
    if (activity.object.collection === "replies") {
      linkHref = `/comments/${activity.object.data.rootCommentShortId}#${activity.object.data.shortId}`;
    }
    if (activity.verb === "repliedToComment" || activity.verb === "alsoRepliedToComment") {
      linkHref = `/comments/${activity.reply.data.rootCommentShortId}#${activity.reply.data.shortId}`;
    }

    return (
      <Link href="/comments/[shortId]" as={linkHref} className="text-inherit no-underline" {...props}>
        {children}
      </Link>
    );
  }

  return children;
}
