import * as NavigationMenuPrimitive from "@radix-ui/react-navigation-menu";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import classNames from "components/ui/classNames";
import { ChevronDownIcon } from "lucide-react";
import type { LinkProps } from "next/link";
import Link from "next/link";
import { useRouter } from "next/router";
import * as React from "react";

const NavigationMenu = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Root>
>(({ className, children, ...props }, ref) => (
  <NavigationMenuPrimitive.Root
    ref={ref}
    className={classNames("relative z-10 flex max-w-max flex-1 items-center justify-center", className)}
    {...props}
  >
    {children}
  </NavigationMenuPrimitive.Root>
));
NavigationMenu.displayName = NavigationMenuPrimitive.Root.displayName;

const NavigationMenuList = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.List>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.List
    ref={ref}
    className={classNames("group flex flex-1 list-none items-center justify-center space-x-1", className)}
    {...props}
  />
));
NavigationMenuList.displayName = NavigationMenuPrimitive.List.displayName;

const NavigationMenuItem = NavigationMenuPrimitive.Item;

const navigationMenuTriggerStyle = cva(
  classNames(
    "inline-flex h-10 w-max items-center rounded-lg px-3 py-2 text-sm font-medium tracking-wide",
    "focus-visible:text-brand focus-visible:outline-none",
    "disabled:pointer-events-none disabled:opacity-50",
    "group",
  ),
  {
    variants: {
      variant: {
        default: classNames(
          "text-gray-600",
          "hover:bg-indigo-50/80 hover:text-brand",
          "data-[state=open]:bg-slate-100/50",
          "dark:text-slate-400",
          "dark:hover:bg-slate-800 dark:hover:text-white",
        ),
        black: "text-neutral-200 hover:bg-slate-950 hover:text-neutral-400 data-[state=open]:bg-slate-800",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

const NavigationMenuTrigger = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Trigger> &
    VariantProps<typeof navigationMenuTriggerStyle>
>(({ className, variant, children, ...props }, ref) => (
  <NavigationMenuPrimitive.Trigger
    ref={ref}
    className={classNames(navigationMenuTriggerStyle({ variant }), "group", className)}
    {...props}
  >
    {children}
    <ChevronDownIcon
      className={classNames(
        "relative ml-1 h-3.5 w-3.5 transition duration-200",
        "group-data-[state=open]:rotate-180",
        "text-gray-400",
      )}
      aria-hidden="true"
      absoluteStrokeWidth={true}
      strokeWidth={3}
    />
  </NavigationMenuPrimitive.Trigger>
));
NavigationMenuTrigger.displayName = NavigationMenuPrimitive.Trigger.displayName;

const navigationMenuContentStyle = cva(classNames("absolute top-full mt-1 rounded-xl border shadow-md"), {
  variants: {
    variant: {
      default:
        "border-slate-300/80 bg-white dark:bg-slate-700 dark:border-slate-950 dark:shadow-lg dark:shadow-neutral-800",
      black: "border-slate-700 bg-neutral-700",
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

const NavigationMenuContent = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Content> &
    VariantProps<typeof navigationMenuContentStyle>
>(({ className, variant, ...props }, ref) => (
  <NavigationMenuPrimitive.Content
    ref={ref}
    className={classNames(navigationMenuContentStyle({ variant }), className)}
    {...props}
  />
));
NavigationMenuContent.displayName = NavigationMenuPrimitive.Content.displayName;

const NavigationMenuLink = NavigationMenuPrimitive.Link;

const NavigationMenuIndicator = React.forwardRef<
  React.ElementRef<typeof NavigationMenuPrimitive.Indicator>,
  React.ComponentPropsWithoutRef<typeof NavigationMenuPrimitive.Indicator>
>(({ className, ...props }, ref) => (
  <NavigationMenuPrimitive.Indicator
    ref={ref}
    className={classNames("top-full z-[1] flex h-1.5 items-end justify-center overflow-hidden", className)}
    {...props}
  >
    <div className="bg-border relative top-[60%] h-2 w-2 rotate-45 rounded-tl-sm shadow-md" />
  </NavigationMenuPrimitive.Indicator>
));
NavigationMenuIndicator.displayName = NavigationMenuPrimitive.Indicator.displayName;

interface NavigationMenuItemLinkProps extends LinkProps, VariantProps<typeof navigationMenuTriggerStyle> {
  href: string;
  children: React.ReactNode;
  className?: string;
  exact?: boolean;
}

function NavigationMenuItemLink({ href, exact, children, className, variant, ...props }: NavigationMenuItemLinkProps) {
  const router = useRouter();
  const isActive = exact ? router.pathname === href : router.pathname.startsWith(href);

  return (
    <NavigationMenuItem className="px-1">
      <Link
        href={href}
        as={href}
        passHref={true}
        data-active={isActive || undefined}
        className={classNames(
          navigationMenuTriggerStyle({ variant }),
          `data-[active]:text-indigo-600 dark:data-[active]:font-bold dark:data-[active]:text-white`,
          className,
        )}
        {...props}
      >
        {children}
      </Link>
    </NavigationMenuItem>
  );
}

interface NavigationMenuUnorderedListProps extends React.ComponentPropsWithoutRef<"ul"> {
  children: React.ReactNode;
  className?: string;
}
function NavigationMenuUnorderedList({ children, className, ...props }: NavigationMenuUnorderedListProps) {
  return (
    <ul className={classNames("p-1", className)} {...props}>
      {children}
    </ul>
  );
}

const navigationMenuListItemLinkStyle = cva(classNames("w-full whitespace-nowrap text-left"), {
  variants: {
    variant: {
      default: classNames(
        "hover:bg-gray-100 hover:text-gray-800",
        "data-[active]:text-indigo-600 hover:data-[active]:text-indigo-600",
        "data-[active]:hover:bg-indigo-50 dark:text-neutral-300",
      ),
      black: classNames(
        "text-neutral-200 hover:bg-neutral-500 hover:text-neutral-400",
        "data-[active]:text-indigo-600 hover:data-[active]:text-indigo-600",
        "data-[active]:hover:bg-indigo-50",
      ),
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

function NavigationMenuListItemLink({
  href,
  exact,
  children,
  className,
  variant,
  ...props
}: NavigationMenuItemLinkProps & VariantProps<typeof navigationMenuTriggerStyle>) {
  const router = useRouter();
  const isActive = exact ? router.pathname === href : router.pathname.startsWith(href);

  return (
    <li>
      <NavigationMenuLink asChild={true}>
        <Link
          href={href}
          as={href}
          passHref={true}
          data-active={isActive || undefined}
          className={classNames(navigationMenuTriggerStyle(), navigationMenuListItemLinkStyle({ variant }), className)}
          {...props}
        >
          {children}
        </Link>
      </NavigationMenuLink>
    </li>
  );
}

export {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuIndicator,
  NavigationMenuItem,
  NavigationMenuItemLink,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuListItemLink,
  NavigationMenuTrigger,
  NavigationMenuUnorderedList,
  navigationMenuTriggerStyle,
};
