/* eslint-disable react/no-unstable-nested-components */
import ActivatePremiumDialog from "components/billing/ActivatePremiumDialog";
import classNames from "components/ui/classNames";
import useSelfie from "lib/hooks/useSelfie";
import { FormattedMessage } from "react-intl";

export default function TrialStickyBanner() {
  const viewer = useSelfie();

  if (!viewer?.isTrialing) {
    return null;
  }
  return (
    <div
      className={classNames(
        "w-full border-b border-transparent bg-sky-700 py-2 text-white",
        "dark:border-slate-950 dark:bg-blue-600/10 dark:text-blue-200",
      )}
    >
      <ActivatePremiumDialog>
        {({ isLoading }) => (
          <p className="font-medium">
            <FormattedMessage
              defaultMessage="Você está explorando as funcionalidades do Fundamentei com dados limitados durante seu teste gratuito de 7 dias. <activateSubscriptionButton>Clique para ativar sua assinatura Premium</activateSubscriptionButton> e ter acesso completo!"
              id="N83BeE"
              values={{
                activateSubscriptionButton: (text) => {
                  return (
                    <button
                      disabled={isLoading}
                      type="button"
                      className={classNames(
                        "inline-flex text-sm font-medium tracking-wider text-yellow-300 underline-offset-4 hover:underline",
                      )}
                    >
                      {isLoading ? <FormattedMessage defaultMessage="Aguarde..." id="VZazm7" /> : text}
                    </button>
                  );
                },
              }}
            />
          </p>
        )}
      </ActivatePremiumDialog>
    </div>
  );
}
