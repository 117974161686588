import gql from "graphql-tag";
import UserSettingsV2Fields from "lib/graphql/fragments/UserSettingsV2Fields";

export default gql`
  mutation ChangeUserSetting($key: ID!, $value: JSON!) {
    changeUserSetting(key: $key, value: $value) {
      ...UserSettingsV2Fields
    }
  }
  ${UserSettingsV2Fields}
`;
