/* eslint-disable react-hooks/exhaustive-deps */
import type { GetFeedOptions, NotificationActivityEnriched, StreamFeed } from "getstream";
import * as stream from "getstream";
import useAsyncFetchMore from "lib/hooks/useAsyncFetchMore";
import type { ReactElement } from "react";
import { useEffect, useState } from "react";
import { useAsyncCallback } from "react-async-hook";
import type { NotificationsUnion } from "./types";

interface INotificationsFeedStreamChildrenState {
  loading: boolean;
  loadingMore: boolean;
  totalUnread: number;
  totalUnseen: number;
  notifications: NotificationsUnion[];
  hasNextPage: boolean;
  markAllAsRead: () => void;
  markThoseAsRead: (ids: string[]) => void;
  markSingleAsRead: (id: string) => void;
  loadMoreNotifications: () => void;
}

interface INotificationsFeedStreamProps {
  appId: string;
  appKey: string;
  doNotRequest: boolean;
  location: string;
  userToken: string;
  userId: string;
  limit: number;
  limitPerPage: number;
  children: (state: INotificationsFeedStreamChildrenState) => ReactElement;
}

export default function NotificationsFeedStream({
  appId,
  appKey,
  doNotRequest = false,
  location,
  userToken,
  userId,
  limit,
  limitPerPage,
  children,
}: INotificationsFeedStreamProps) {
  const client = stream.connect(appKey, userToken, appId, {
    location: location === "us-east-1" ? "us-east" : location,
  });

  // @ts-ignore
  const feed = client.feed("notifications_v2", userId, userToken);

  // NOTE: this is used to keep track of all the notifications that were marked as read by the user. We have to do it
  // in memory so it reflects right away in the UI when the user performs a read operation
  const [markedAsRead, setMarkedAsRead] = useState<string[]>([]);

  const notificationsPerPage = Math.min(Math.max(limit, 0), limitPerPage);

  const fetchAll = useAsyncCallback(async () => {
    return fetchOnNotificationsFeedWithParameters(feed, notificationsPerPage);
  });

  const {
    canFetchMore,
    fetchMore,
    loading: loadingMore,
  } = useAsyncFetchMore({
    value: fetchAll,
    fetchMore: async (currentResult) => {
      if (!currentResult.hasNextPage || currentResult.notifications.length === 0) {
        return currentResult;
      }

      const lastSeenNotification = currentResult.notifications[currentResult.notifications.length - 1];
      return fetchOnNotificationsFeedWithParameters(feed, notificationsPerPage, lastSeenNotification.id);
    },
    isEnd: ({ hasNextPage }) => !hasNextPage,
    merge: (currentResult, fetchMoreResult) => ({
      ...currentResult,
      notifications: currentResult.notifications.concat(fetchMoreResult.notifications),
      hasNextPage: fetchMoreResult.hasNextPage,
    }),
  });

  // Fetch all the notifications on mount
  useEffect(() => {
    if (doNotRequest) {
      return;
    }

    fetchAll.execute();
  }, []);

  const notifications = (fetchAll.result ? fetchAll.result.notifications || [] : []).map((notification) => {
    if (notification.id && markedAsRead.includes(notification.id)) {
      return {
        ...notification,
        is_read: true,
        is_seen: true,
      };
    }

    return notification;
  });

  return children({
    loading: fetchAll.loading,
    loadingMore,
    totalUnread: Math.max(fetchAll.result ? fetchAll.result.totalUnread - markedAsRead.length : 0, 0),
    totalUnseen: Math.max(fetchAll.result ? fetchAll.result.totalUnseen - markedAsRead.length : 0, 0),
    notifications: notifications as NotificationsUnion[],
    hasNextPage: fetchAll.result ? fetchAll.result.hasNextPage : false,
    markAllAsRead: async () => {
      if (notifications) {
        const currentlyUnread = notifications.filter((notification) => notification.is_read === false);

        setMarkedAsRead([
          // keep all the notifications we've already read previously
          ...markedAsRead,
          // mark all notifications we've just marked as read but filter out those we've already marked as read. By
          // doing so it doesn't blow up memory
          ...currentlyUnread.map((notification) => notification.id).filter((id) => !markedAsRead.includes(id)),
        ]);
      }

      if (fetchAll.result) {
        fetchAll.merge({
          result: {
            ...fetchAll.result,
            totalUnread: 0,
            totalUnseen: 0,
          },
        });
      }

      await feed.get({
        mark_read: true,
        mark_seen: true,
      });
    },
    markThoseAsRead: async (ids) => {
      const safeIds = ids.filter(Boolean);

      if (notifications) {
        const currentlyUnread = notifications.filter((notification) => {
          return notification.is_read === false && ids.includes(notification.id);
        });

        // we don't need to trigger a request/rerender if all those notifications are read
        if (currentlyUnread.length === 0) {
          return;
        }

        setMarkedAsRead([
          // keep all the notifications we've already read previously
          ...markedAsRead,
          // mark all notifications we've just marked as read but filter out those we've already marked as read. By
          // doing so it doesn't blow up memory
          ...currentlyUnread.map((notification) => notification.id).filter((id) => !markedAsRead.includes(id)),
        ]);
      }

      await feed.get({
        mark_read: safeIds,
        mark_seen: safeIds,
      });
    },
    markSingleAsRead: async (id) => {
      if (!id) {
        return;
      }

      const isRead = notifications.some((notification) => {
        return notification.id === id && notification.is_read;
      });

      // we don't need to trigger a request/rerender if it's already read
      if (isRead) {
        return;
      }

      setMarkedAsRead([...markedAsRead, id]);

      await feed.get({
        mark_read: [id],
        mark_seen: [id],
      });
    },
    loadMoreNotifications: () => {
      if (canFetchMore) {
        fetchMore();
      }
    },
  });
}

async function fetchOnNotificationsFeedWithParameters(feed: StreamFeed, limit: number, beforeThisId?: string) {
  const parameters: GetFeedOptions = {
    limit,
    id_lt: beforeThisId,
    enrich: true,
  };

  const response = await feed.get(parameters);

  return {
    totalUnread: response.unread || 0,
    totalUnseen: response.unseen || 0,
    notifications: response.results as NotificationActivityEnriched[],
    hasNextPage: response.next !== "",
  };
}
