import type { ReactElement } from "react";
import * as React from "react";
import { royalBlue } from "../palette";

export default function Fundamentei({ fill, ...props }: React.SVGProps<any>): ReactElement {
  return (
    <svg
      width={372}
      height={392}
      viewBox="0 0 372 392"
      xmlns="http://www.w3.org/2000/svg"
      className="inline-flex"
      {...props}
    >
      <title>Fundamentei</title>
      <g fill={fill || royalBlue} fillRule="nonzero">
        <path d="M287.045.032 242.14 45.08l44.493 44.637c22.72 22.794 22.72 59.759 0 82.553L112.867 346.602 158.117 392l180.309-180.894c43.923-44.066 43.923-115.492 0-159.558L287.045 0v.032Z" />
        <path d="m84.324 391.968 44.903-45.049-44.492-44.637c-22.72-22.794-22.72-59.759 0-82.553L258.502 45.398 213.25 0 32.943 180.894c-43.924 44.066-43.924 115.492 0 159.558L84.323 392v-.032Z" />
      </g>
    </svg>
  );
}
