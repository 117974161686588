/* eslint-disable react-hooks/exhaustive-deps */
import { MoveLeftIcon } from "lucide-react";
import type { ReactNode } from "react";
import { useCallback, useState } from "react";
import { FormattedMessage } from "react-intl";
import Scrollbar from "react-scrollbars-custom";
import { useMountedState } from "react-use";

interface INotificationsFlyoutBoxProps {
  unreadUnseenCount: number;
  markAllAsRead: () => void;
  onToggle?: () => void;
  children: ReactNode;
}

export default function NotificationsFlyoutBox({
  unreadUnseenCount,
  markAllAsRead,
  onToggle,
  children,
}: INotificationsFlyoutBoxProps) {
  const [isScrolling, setIsScrolling] = useState(false);
  const [isMouseOver, setIsMouseOver] = useState(false);
  const isMounted = useMountedState();

  const onScrollStart = useCallback(() => {
    if (isMounted()) {
      setIsScrolling(true);
    }
  }, []);
  const onScrollStop = useCallback(() => {
    if (isMounted()) {
      setIsScrolling(false);
    }
  }, []);
  const onMouseEnter = useCallback(() => {
    if (isMounted()) {
      setIsMouseOver(true);
    }
  }, []);
  const onMouseLeave = useCallback(() => {
    if (isMounted()) {
      setIsMouseOver(false);
    }
  }, []);

  const isShowingTrack = isScrolling || isMouseOver;
  const canMarkAllAsRead = unreadUnseenCount > 0;

  return (
    <div className="h-screen font-aeonik lg:h-auto lg:w-[444px]">
      <div className="flex items-center rounded-t-xl border-b bg-gray-50 p-3 text-sm font-medium tracking-wide dark:border-b-slate-950 dark:bg-slate-900">
        <button
          className="flex items-center gap-x-2 font-aeonik text-sm font-medium text-blue-500 lg:hidden"
          type="button"
          onClick={onToggle && onToggle}
        >
          <MoveLeftIcon />
          <h5 className="text-gray-500 dark:text-slate-300">
            <FormattedMessage defaultMessage="Notificações" id="l6gpWH" />
          </h5>
        </button>

        <h5 className="hidden text-gray-500 dark:text-slate-300 lg:flex">
          <FormattedMessage defaultMessage="Notificações" id="l6gpWH" />
        </h5>

        {canMarkAllAsRead && (
          <div className="ml-auto">
            <button
              type="button"
              aria-label="Marcar todas notificações como lidas"
              onClick={markAllAsRead}
              className="hover:underline dark:text-teal-200"
            >
              <FormattedMessage defaultMessage="Marcar como Lidas" id="Hp/ne4" />
            </button>
          </div>
        )}
      </div>

      <Scrollbar
        noScrollX={true}
        translateContentSizeYToHolder={true}
        removeTracksWhenNotUsed={true}
        scrollDetectionThreshold={500}
        onScrollStart={onScrollStart}
        onScrollStop={onScrollStop}
        className="max-h-[calc(100vh-46px)] w-full lg:max-h-[420px] lg:w-[444px]"
        wrapperProps={{
          style: {
            right: 0,
          },
        }}
        trackYProps={{
          style: {
            top: 8,
            display: "flex",
            justifyContent: "center",
            width: 16,
            height: "calc(100% - 16px)",
            backgroundColor: "transparent",
            borderRadius: 0,
            opacity: isShowingTrack ? 1 : 0,
            transition: "opacity 0.4s ease-in-out",
          },
          onMouseEnter,
          onMouseLeave,
        }}
        thumbYProps={{
          style: {
            width: 5,
            backgroundColor: "rgba(0, 0, 0, 0.4)",
            borderRadius: 10,
          },
        }}
        contentProps={{
          style: {
            // https://stackoverflow.com/a/42015631/2895842
            width: "1%",
          },
        }}
      >
        <div className="h-full w-full">{children}</div>
      </Scrollbar>
    </div>
  );
}
