import classNames from "components/ui/classNames";
import useTheme from "components/ui/useTheme";
import stringHashCode from "lib/utils/stringHashCode";
import Image from "next/image";
import { forwardRef, useState } from "react";
import tinycolor from "tinycolor2";

interface AvatarProps {
  src: string | null;
  hashValue?: string;
  className?: string;
  variant?: "rounded" | "squared";
  squaredRadius?: number;
  squircle?: boolean;
  alt: string;
  title?: string;
  size: number;
  style?: React.CSSProperties;
  priority?: boolean;
}

const LIGHT_AVATAR_COLORS = [
  "#555e6e",
  "#bd0e0f",
  "#9c4303",
  "#805501",
  "#016d00",
  "#056874",
  "#1358cc",
  "#4a46e1",
  "#8335c8",
  "#b90263",
];
const DARK_AVATAR_COLORS = [
  "#b7bbc2",
  "#eaa6a6",
  "#e5ad8b",
  "#d9bd2f",
  "#85c8ac",
  "#69c8ce",
  "#95bbee",
  "#a5b8e9",
  "#bcb2f2",
  "#e9a7cc",
];

function Avatar(
  {
    src,
    hashValue,
    alt,
    size,
    variant = "rounded",
    squaredRadius = 4,
    squircle = false,
    className,
    style,
    priority,
    ...props
  }: AvatarProps,
  ref: React.Ref<any>,
) {
  const theme = useTheme();
  const [failedToLoad, setFailedToLoad] = useState(false);
  if (!src || failedToLoad) {
    const lightBackgroundColor = LIGHT_AVATAR_COLORS[stringHashCode(hashValue || alt) % LIGHT_AVATAR_COLORS.length];
    const darkBackgroundColor = DARK_AVATAR_COLORS[stringHashCode(hashValue || alt) % DARK_AVATAR_COLORS.length];

    return (
      <span
        ref={ref}
        title={alt}
        draggable={false}
        className={classNames(
          "box-border inline-flex select-none items-center justify-center text-base font-bold",
          className,
        )}
        style={{
          minWidth: size,
          width: size,
          height: size,
          backgroundColor:
            theme === "dark" ? tinycolor(darkBackgroundColor).setAlpha(0.25).toRgbString() : lightBackgroundColor,
          color: theme === "dark" ? darkBackgroundColor : "white",
          borderRadius: variant === "rounded" ? "100%" : squaredRadius,
          WebkitBackfaceVisibility: "hidden",
          backfaceVisibility: "hidden",
          clipPath: squircle ? "var(--squircle-polygon)" : undefined,
          WebkitClipPath: squircle ? "var(--squircle-polygon)" : undefined,
          ...style,
        }}
        {...props}
      >
        {alt.substring(0, 1).toUpperCase()}
      </span>
    );
  }

  return (
    <Image
      ref={ref}
      alt={alt}
      {...props}
      unoptimized={true}
      src={src}
      width={size}
      height={size}
      className={className}
      onError={() => setFailedToLoad(true)}
      priority={priority}
      style={{
        minWidth: size,
        width: size,
        height: size,
        borderRadius: variant === "rounded" ? "100%" : squaredRadius,
        WebkitBackfaceVisibility: "hidden",
        backfaceVisibility: "hidden",
        clipPath: squircle ? "var(--squircle-polygon)" : undefined,
        WebkitClipPath: squircle ? "var(--squircle-polygon)" : undefined,
        ...style,
      }}
    />
  );
}

export default forwardRef(Avatar);
