import Link from "next/link";
import type { MouseEventHandler } from "react";
import * as React from "react";
import classNames from "components/ui/classNames";
import type { Asset } from "../../types";

interface ILinkToAssetProps extends React.HTMLAttributes<HTMLAnchorElement> {
  asset: Asset;
  preventPropagation?: boolean;
}

export default function LinkToAsset({ asset, preventPropagation }: ILinkToAssetProps) {
  const className = classNames(
    "min-w-0 font-bold leading-5 text-blue-800 no-underline hover:underline dark:text-blue-300",
  );

  const handleClick: MouseEventHandler<HTMLAnchorElement> = (event) => {
    if (preventPropagation) {
      event.stopPropagation();
    }
  };

  if (asset.data.assetType === "AMERICAN_COMPANY") {
    const linkHref = `/us/${asset.data.tickerSymbolPrefix.toLowerCase()}`;

    return (
      <Link href="/us/[tickerSymbol]" as={linkHref} passHref={true} className={className} onClick={handleClick}>
        {asset.data.name}
      </Link>
    );
  }

  if (asset.data.assetType === "BRAZILIAN_REAL_ESTATE_FUND") {
    const linkHref = `/fiis/${asset.data.tickerSymbolPrefix.toLowerCase()}11`;

    return (
      <Link href="/fiis/[tickerSymbol]" as={linkHref} passHref={true} className={className} onClick={handleClick}>
        {asset.data.name}
      </Link>
    );
  }

  const linkHref = `/br/${asset.data.tickerSymbolPrefix.toLowerCase()}`;

  return (
    <Link href="/br/[tickerSymbol]" as={linkHref} passHref={true} className={className} onClick={handleClick}>
      {asset.data.name}
    </Link>
  );
}
