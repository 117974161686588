// eslint-disable-next-line import/no-extraneous-dependencies
import type { MultipleQueriesQuery } from "@algolia/client-search";
import classNames from "components/ui/classNames";
import useTheme from "components/ui/useTheme";
import Downshift from "downshift";
import indexes from "lib/algolia/indexes";
import searchClient from "lib/algolia/searchClient";
import { getPlatformVerticalFromIssuerObject, type IIssuerObject } from "lib/algolia/types/assets";
import type { TAmplitude } from "lib/amplitude/Amplitude";
import { useAmplitude, useDebouncedAmplitude } from "lib/amplitude/Amplitude";
import useSelfie from "lib/hooks/useSelfie";
import debounce from "lodash/fp/debounce";
import isEqual from "lodash/fp/isEqual";
import { useRouter } from "next/router";
import * as React from "react";
import { createRef, memo } from "react";
import type { AutocompleteProvided } from "react-instantsearch-core";
import { Configure } from "react-instantsearch-core";
import { connectAutoComplete, InstantSearch } from "react-instantsearch-dom";
import { useIntl } from "react-intl";
import { Value } from "react-powerplug";

interface NavbarSearchInputProps {
  onChange: (issuer: IIssuerObject, searchQuery: string) => void;
  debounceRefineMs?: number;
}

export default function NavbarSearchInput({ debounceRefineMs, onChange }: NavbarSearchInputProps) {
  const amplitude = useDebouncedAmplitude(600);

  return (
    <InstantSearch
      indexName={indexes.issuers}
      searchClient={{
        search: (queries: readonly MultipleQueriesQuery[]): Readonly<Promise<any>> => {
          const isEmptySearch = queries.every(({ params }) => {
            return params ? params.query === "" : true;
          });

          if (isEmptySearch) {
            return Promise.resolve([]);
          }

          return searchClient.search(queries);
        },
      }}
    >
      <Configure typoTolerance="false" hitsPerPage={6} />
      <ConnectedTypeahead amplitude={amplitude} debounceRefineMs={debounceRefineMs} onChange={onChange} />
    </InstantSearch>
  );
}

interface IssuersTypeaheadProps {
  amplitude?: TAmplitude;
  debounceRefineMs?: number;
  onChange?: (issuer: IIssuerObject, searchQuery: string) => void;
}

const IssuersTypeahead = memo(
  ({ refine, hits, debounceRefineMs = 180, onChange }: AutocompleteProvided<IIssuerObject> & IssuersTypeaheadProps) => {
    const searchInputRef = createRef<HTMLInputElement>();
    const amplitude = useAmplitude();
    const router = useRouter();
    const isValuationContext = router.pathname.endsWith("/valuation");
    const intl = useIntl();
    const viewer = useSelfie();
    const theme = useTheme();

    const isDark = (viewer && viewer.isBlack) || theme === "dark";

    return (
      <Value initial="" onChange={debounce(debounceRefineMs, refine)}>
        {({ value, set }) => (
          <Downshift
            id="IssuersTypeahead"
            itemToString={(item: IIssuerObject | null) => (item ? item.tickerSymbolPrefix : "")}
            inputValue={value}
            onChange={(hit) => {
              if (!hit) {
                return;
              }

              // Commit change and lose focus
              if (onChange && typeof onChange === "function") {
                onChange(hit, searchInputRef.current?.value ?? "");

                if (hit.tickerSymbols.length > 0) {
                  amplitude.logEvent("Confirmed issuer search", {
                    vertical: getPlatformVerticalFromIssuerObject(hit),
                    tickerSymbol: hit.tickerSymbols[0],
                    origin: "Navbar Typeahead",
                  });
                }

                if (searchInputRef.current) {
                  set("");
                  searchInputRef.current.blur();
                }
              }
            }}
            stateReducer={(state, changes) => {
              switch (changes.type) {
                case Downshift.stateChangeTypes.keyDownArrowDown:
                case Downshift.stateChangeTypes.keyDownArrowUp:
                  return {
                    ...changes,
                    isOpen: state.inputValue !== "",
                  };
                case Downshift.stateChangeTypes.changeInput:
                  return {
                    ...changes,
                    isOpen: changes.inputValue !== "",
                    highlightedIndex: null,
                  };
                case Downshift.stateChangeTypes.blurInput:
                  return {
                    isOpen: false,
                  };
                default:
                  return changes;
              }
            }}
          >
            {({ getInputProps, getItemProps, getMenuProps, openMenu, highlightedIndex, inputValue, isOpen }) => {
              const hasSearch = (inputValue || "").trim().length > 0;
              const hasResults = hits.length > 0;

              return (
                <div className="lg:relative">
                  <div className="relative">
                    <input
                      spellCheck={false}
                      maxLength={18}
                      placeholder={intl.formatMessage({
                        defaultMessage: "Pesquisar...",
                        id: "EQnBzf",
                        description: "Placeholder for search input",
                      })}
                      className={classNames(
                        "h-10 w-full rounded-xl border border-slate-300 bg-slate-100 px-4 py-3 pr-12 text-base font-bold text-violet-600 shadow-inner",
                        "sm:text-sm",
                        "lg:h-11 lg:bg-slate-50 lg:shadow-none",
                        "placeholder:text-sm placeholder:font-medium placeholder:tracking-wide placeholder:text-slate-500",
                        "focus:shadow focus:outline-none",
                        "lg:placeholder:font-normal",
                        {
                          "border-neutral-900 bg-neutral-700 text-neutral-400 placeholder:text-neutral-500 lg:bg-neutral-700":
                            isDark,
                        },
                      )}
                      {...getInputProps({
                        ref: searchInputRef,
                        onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
                          if (event.key === "Enter" && hits.length === 1 && typeof onChange === "function") {
                            onChange(hits[0], searchInputRef.current?.value ?? "");
                          }
                        },
                        onChange: (event: React.ChangeEvent<HTMLInputElement>) => set(event.currentTarget.value),
                        onFocus: () => {
                          if (hasSearch && hits.length > 0) {
                            openMenu();
                          }
                        },
                      })}
                    />

                    <div className="pointer-events-none absolute inset-y-0 right-0 top-0 flex items-center pr-4">
                      <MagnifyingGlassIcon
                        className={classNames("h-5 w-5 stroke-violet-400", {
                          "fill-blue-700 stroke-neutral-400": isDark,
                        })}
                        fill={isDark ? "#525252" : "#F5F3FF"}
                        aria-hidden="true"
                      />
                    </div>
                  </div>

                  {hasSearch && hasResults && isOpen && (
                    <div
                      className={classNames(
                        "absolute left-0 z-10 mt-2 w-full bg-white px-2 py-3 shadow-xl dark:border-slate-900 dark:bg-slate-800",
                        "lg:w-[448px] lg:rounded-xl lg:border lg:border-slate-200",
                      )}
                    >
                      <div {...getMenuProps()} className="w-full">
                        {hits.slice(0, 6).map((hit, index) => {
                          const isSelected = highlightedIndex === index;
                          const key = `${hit._id}/${hit.tickerSymbolPrefix}`;
                          const { as } = isValuationContext
                            ? getLinkToIssuerValuation(hit)
                            : getLinkToIssuerOverview(hit);

                          return (
                            <a
                              key={key}
                              href={as}
                              className={classNames(
                                "relative flex cursor-pointer justify-between border border-transparent p-3",
                                classNames({
                                  "rounded-xl bg-violet-50 dark:bg-slate-950/90": isSelected && !isValuationContext,
                                  "rounded-md bg-green-50": isSelected && isValuationContext,
                                }),
                              )}
                              {...getItemProps({
                                item: hit,
                                index,
                                onClick: (event) => {
                                  if (!(event.shiftKey || event.ctrlKey || event.altKey || event.metaKey)) {
                                    event.preventDefault();
                                    return;
                                  }

                                  if (event.nativeEvent) {
                                    // eslint-disable-next-line no-param-reassign
                                    (event.nativeEvent as any).preventDownshiftDefault = true;
                                  }

                                  event.stopPropagation();
                                },
                              })}
                            >
                              <div className="space-y-1 tracking-wide">
                                <div>
                                  <strong
                                    className={classNames(
                                      classNames("text-sm font-bold text-gray-500 dark:text-gray-200", {
                                        "text-violet-600 dark:text-orange-400": !isValuationContext && isSelected,
                                        "text-green-800": isValuationContext && isSelected,
                                      }),
                                    )}
                                  >
                                    {hit.name}
                                  </strong>
                                </div>
                                <div className="flex items-center space-x-2">
                                  <span className="text-xs font-medium italic text-gray-400 dark:text-slate-400">
                                    {hit.type !== "BRAZILIAN_REAL_ESTATE_FUND"
                                      ? hit.industrySector
                                      : hit.industrySegment}
                                  </span>
                                </div>
                                <span
                                  className={classNames(
                                    "rounded-md px-1.5 py-0.5 text-[11px] font-bold tracking-wider",
                                    "bg-violet-50 text-violet-600",
                                    "dark:bg-orange-600/30 dark:text-orange-100",
                                    "inline-flex",
                                  )}
                                >
                                  {hit.tickerSymbolPrefix}
                                </span>
                              </div>

                              <div className="flex flex-col items-end">
                                {hit.logoUrl && (
                                  <img
                                    role="presentation"
                                    src={hit.logoUrl}
                                    onContextMenu={(event) => event.preventDefault()}
                                    onMouseDown={(event) => event.preventDefault()}
                                    alt={hit.name}
                                    draggable={false}
                                    className="h-8 w-20 select-none object-contain object-right dark:invert-50"
                                  />
                                )}

                                {(hit.type === "BRAZILIAN_REAL_ESTATE_FUND" || hit.type === "BRAZILIAN_COMPANY") && (
                                  <div
                                    className={classNames(
                                      "ml-auto mt-auto",
                                      classNames({
                                        "flex items-center space-x-2": isValuationContext && isSelected,
                                      }),
                                    )}
                                  >
                                    {isValuationContext && isSelected && (
                                      <div
                                        className={classNames(
                                          "flex items-center space-x-1 rounded-full",
                                          "border border-emerald-600 px-2 text-xs font-medium text-emerald-600",
                                        )}
                                      >
                                        Valuation
                                      </div>
                                    )}
                                  </div>
                                )}

                                {(hit.type === "AMERICAN_COMPANY" || hit.type === "AMERICAN_REIT") &&
                                  hit.headquarters && (
                                    <div
                                      className={classNames(
                                        "ml-auto mt-auto",
                                        classNames({
                                          "flex items-center space-x-2": isValuationContext && isSelected,
                                        }),
                                      )}
                                    >
                                      {isValuationContext && isSelected && (
                                        <div
                                          className={classNames(
                                            "flex items-center space-x-1 rounded-full",
                                            "border border-emerald-600 px-2 text-xs font-medium text-emerald-600",
                                          )}
                                        >
                                          Valuation
                                        </div>
                                      )}

                                      <img
                                        role="presentation"
                                        alt={hit.headquarters.countryName}
                                        src={`https://s.fndm.to/svg/common/flags/${hit.headquarters.countryCode}.svg`}
                                        onContextMenu={(event) => event.preventDefault()}
                                        onMouseDown={(event) => event.preventDefault()}
                                        draggable={false}
                                        className="h-4 w-4 select-none object-cover"
                                        style={{
                                          filter: isSelected ? undefined : "grayscale(1)",
                                          opacity: isSelected ? 1 : 0.2,
                                        }}
                                      />
                                    </div>
                                  )}
                              </div>
                            </a>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              );
            }}
          </Downshift>
        )}
      </Value>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps.hits, nextProps.hits),
);

const ConnectedTypeahead = connectAutoComplete<
  AutocompleteProvided<IIssuerObject> & IssuersTypeaheadProps,
  IIssuerObject
>(IssuersTypeahead);

function MagnifyingGlassIcon({ fill, ...rest }: React.SVGProps<any>) {
  return (
    <svg className="h-5 w-5" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...rest}>
      <g strokeWidth={1.5} fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
        <path d="M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0h0Z" fill={fill} />
        <path d="m14.251 14.251-2.489-2.489M11.762 11.762a3.375 3.375 0 1 0-4.775-4.775 3.375 3.375 0 0 0 4.776 4.776l-.001-.001Z" />
      </g>
    </svg>
  );
}

export function getLinkToIssuerOverview(issuer: IIssuerObject) {
  if (issuer.tickerSymbols.length === 0) {
    throw new Error("Missing symbols");
  }
  const tickerSymbol = issuer.tickerSymbols[0].toLowerCase();
  if (issuer.type === "AMERICAN_COMPANY" || issuer.type === "AMERICAN_REIT") {
    return {
      href: "/us/[tickerSymbol]",
      as: `/us/${tickerSymbol}`,
    };
  }
  if (issuer.type === "BRAZILIAN_COMPANY") {
    return {
      href: "/br/[tickerSymbol]",
      as: `/br/${tickerSymbol}`,
    };
  }
  return {
    href: "/fiis/[tickerSymbol]",
    as: `/fiis/${tickerSymbol}`,
  };
}

export function getLinkToIssuerValuation(issuer: IIssuerObject) {
  if (issuer.tickerSymbols.length === 0) {
    throw new Error("Missing symbols");
  }
  const resolvedTickerSymbol = issuer.tickerSymbols[0].toLowerCase();
  if (issuer.type === "AMERICAN_COMPANY" || issuer.type === "AMERICAN_REIT") {
    return {
      href: "/[country]/[tickerSymbol]/valuation",
      as: `/us/${resolvedTickerSymbol}/valuation`,
    };
  }
  if (issuer.type === "BRAZILIAN_COMPANY") {
    return {
      href: "/[country]/[tickerSymbol]/valuation",
      as: `/br/${resolvedTickerSymbol}/valuation`,
    };
  }
  return {
    href: "/[country]/[tickerSymbol]/valuation",
    as: `/br/${resolvedTickerSymbol}/valuation`,
  };
}

export function getIssuerURLPrefix(issuer: IIssuerObject) {
  if (issuer.type === "AMERICAN_COMPANY" || issuer.type === "AMERICAN_REIT") {
    return "us";
  }
  if (issuer.type === "BRAZILIAN_COMPANY") {
    return "br";
  }
  return "fiis";
}
