/* eslint-disable no-bitwise, no-plusplus */
export default function stringHashCode(rawString: string) {
  let i = rawString.length;
  let hash = 5381;

  while (i) {
    hash = (hash * 33) ^ rawString.charCodeAt(--i);
  }

  return hash >>> 0;
}
