import Link from "next/link";
import { useEffect, useId, useState } from "react";

import Auth from "components/auth/Auth";
import checkCanSkipToBlackCheckout from "components/black/checkCanSkipToBlackCheckout";
import Avatar from "components/core/Avatar";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/Popover";
import classNames from "components/ui/classNames";

import { useAmplitude } from "lib/amplitude/Amplitude";
import type { ISelfieQuery } from "lib/queries/__generated__/SelfieQuery.generated";
import { FormattedMessage } from "react-intl";
import { ChevronDownIcon, ChevronUpIcon } from "lucide-react";

interface ProfileDropdownProps {
  viewer: Exclude<ISelfieQuery["selfie"], null>;
}

export default function ProfileDropdown({ viewer }: ProfileDropdownProps) {
  const amplitude = useAmplitude();
  const profileDropdownButtonId = useId();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isOpen) {
      amplitude.logEvent("Open profile dropdown");
    }
  }, [isOpen, amplitude]);

  return (
    <Popover open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
      <PopoverTrigger aria-label="Menu do perfil" id={profileDropdownButtonId}>
        <div
          className={classNames(
            "rounded-lg border border-transparent bg-neutral-100 p-1",
            {
              "border-violet-200 bg-violet-50 dark:border-transparent": isOpen && !viewer.isBlack,
              "bg-neutral-700 hover:border-neutral-300 hover:bg-neutral-500 hover:text-gray-300": viewer.isBlack,
            },
            "dark:bg-slate-900 dark:hover:border-transparent dark:hover:bg-slate-800 dark:hover:text-white",
          )}
        >
          <div
            className={classNames("flex items-center text-gray-600 dark:text-white", {
              "text-violet-600": isOpen && !viewer.isBlack,
              "text-neutral-300": isOpen && viewer.isBlack,
            })}
          >
            <Avatar
              variant="squared"
              src={viewer.pictureUrl}
              hashValue={viewer._id}
              alt={viewer.fullName}
              size={32}
              squircle={true}
            />

            <div className="ml-3 mr-2">
              {isOpen ? (
                <ChevronUpIcon aria-hidden={true} strokeWidth={2} className="inline-flex h-5 w-5" />
              ) : (
                <ChevronDownIcon aria-hidden={true} strokeWidth={2} className="inline-flex h-5 w-5" />
              )}
            </div>
          </div>
        </div>
      </PopoverTrigger>

      <PopoverContent className={classNames("rounded-xl border border-gray-300 font-aeonik dark:border-slate-900")}>
        <ul
          role="menu"
          tabIndex={-1}
          aria-labelledby={profileDropdownButtonId}
          className={classNames(
            "list-none space-y-1 px-2 py-2 text-sm font-medium text-gray-500 outline-none dark:text-slate-400",
          )}
        >
          {checkCanSkipToBlackCheckout(viewer) && (
            <li role="menuitem">
              <Link
                href="https://hotm.art/HqMWAP0"
                target="_blank"
                onClick={() => {
                  amplitude.logEvent("Click on link to become Black", {
                    origin: "Seja Black - Profile Dropdown",
                  });
                }}
                className={classNames(
                  "inline-flex w-full rounded-md px-3 py-2 font-bold text-black",
                  "hover:bg-stone-100 hover:text-stone-700 dark:bg-slate-950 dark:text-white",
                )}
              >
                Black
              </Link>
            </li>
          )}

          {viewer && !viewer.isPremium && (
            <li role="menuitem">
              <Link
                href="/premium"
                className="inline-flex w-full rounded-md px-3 py-2 hover:bg-violet-50 hover:text-violet-600 dark:hover:bg-violet-600/10 dark:hover:text-violet-200"
              >
                <FormattedMessage defaultMessage="Premium" id="C5xzTC" />
              </Link>
            </li>
          )}

          <li role="menuitem">
            <Link
              href="/settings"
              className="inline-flex w-full rounded-md px-3 py-2 hover:bg-violet-50 hover:text-violet-600 dark:hover:bg-violet-600/10 dark:hover:text-violet-200"
            >
              <FormattedMessage defaultMessage="Conta" id="3aRhp+" />
            </Link>
          </li>

          <li role="menuitem">
            <Link
              href="/support"
              className="inline-flex w-full rounded-md px-3 py-2 hover:bg-violet-50 hover:text-violet-600 dark:hover:bg-violet-600/10 dark:hover:text-violet-200"
            >
              <FormattedMessage defaultMessage="Suporte" id="Di3PGS" />
            </Link>
          </li>

          <li role="menuitem">
            <Link
              href="/coupons"
              className="inline-flex w-full rounded-md px-3 py-2 hover:bg-violet-50 hover:text-violet-600 dark:hover:bg-violet-600/10 dark:hover:text-violet-200"
            >
              <FormattedMessage defaultMessage="Descontos" id="rzBwGI" />
            </Link>
          </li>

          <li role="menuitem">
            <a
              href="https://help.fundamentei.com/pt-BR/collections/5579574-atualizacoes"
              target="_blank"
              rel="noopener noreferrer"
              title="Changelog"
              onClick={() => amplitude.logEvent("View Changelog")}
              className="inline-flex w-full rounded-md px-3 py-2 hover:bg-violet-50 hover:text-violet-600 dark:hover:bg-violet-600/10 dark:hover:text-violet-200"
            >
              <FormattedMessage defaultMessage="Novidades" id="cOUMGL" />
            </a>
          </li>

          <li role="menuitem">
            <a
              href="https://linktr.ee/guiadeir"
              target="_blank"
              rel="noopener noreferrer"
              title="Guia IRPF"
              onClick={() => amplitude.logEvent("Click on Guia IRPF")}
              className="inline-flex w-full rounded-md px-3 py-2 hover:bg-violet-50 hover:text-violet-600 dark:hover:bg-violet-600/10 dark:hover:text-violet-200"
            >
              Guia IRPF
            </a>
          </li>

          <li role="menuitem">
            <a
              href="https://youtube.com/live/YrFNZbyI4TU"
              target="_blank"
              rel="noopener noreferrer"
              title="Tutorial"
              onClick={() => amplitude.logEvent("Click on Tutorial")}
              className="inline-flex w-full rounded-md px-3 py-2 hover:bg-violet-50 hover:text-violet-600 dark:hover:bg-violet-600/10 dark:hover:text-violet-200"
            >
              <FormattedMessage defaultMessage="Tutorial" id="Ox+Z+K" />
            </a>
          </li>

          <li role="menuitem">
            <Auth>
              {({ logout }) => (
                <button
                  type="button"
                  className="inline-flex w-full rounded-md px-3 py-2 hover:bg-violet-50 hover:text-violet-600 dark:hover:bg-violet-600/10 dark:hover:text-violet-200"
                  onClick={() => {
                    setIsOpen(false);
                    logout();
                  }}
                >
                  <FormattedMessage defaultMessage="Sair" id="v9ZmKc" />
                </button>
              )}
            </Auth>
          </li>
        </ul>
      </PopoverContent>
    </Popover>
  );
}
