import classNames from "components/ui/classNames";
import { CheckIcon } from "lucide-react";
import type { FocusEvent } from "react";
import * as React from "react";

type TDivPropsWithoutEventHandlers = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  "onFocus" | "onChange" | "onBlur"
>;

export interface ICheckboxProps extends TDivPropsWithoutEventHandlers {
  name?: string;
  checked?: boolean;
  disabled?: boolean;
  onFocus?: (event: FocusEvent<HTMLDivElement>) => void;
  onChange: (isChecked: boolean) => void;
  onBlur?: (event: FocusEvent<HTMLDivElement>) => void;
}

export default function Checkbox({
  name,
  checked = false,
  disabled = false,
  onFocus,
  onChange,
  onBlur,
  className,
  ...props
}: ICheckboxProps) {
  return (
    <div
      role="checkbox"
      tabIndex={0}
      aria-checked={checked}
      onFocus={onFocus}
      onClick={() => onChange(!checked)}
      onKeyDown={(event) => {
        if (event.key === "Enter" || event.key === " ") {
          onChange(!checked);
        }
      }}
      onBlur={onBlur}
      className={classNames(
        "pointer-events-auto box-border inline-flex w-[18px] h-[18px] cursor-pointer",
        "items-center justify-center rounded-md border border-gray-400 bg-white",
        "disabled:pointer-events-none disabled:bg-gray-100",
        "dark:bg-slate-950 dark:border-transparent dark:text-slate-300",
        className,
      )}
      {...props}
    >
      <input name={name} type="checkbox" className="hidden" />

      {!disabled && checked ? (
        <CheckIcon absoluteStrokeWidth={true} size={18} className="size-2" />
      ) : (
        <div aria-hidden={true} style={{ width: 18, height: 18 }} className="flex" />
      )}
    </div>
  );
}
