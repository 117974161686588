import { useEffect, useRef, useState, useLayoutEffect } from "react";
import type { UseAsyncReturn } from "react-async-hook";
import { useAsyncCallback } from "react-async-hook";

const useIsomorphicLayoutEffect =
  typeof window !== "undefined" &&
  typeof window.document !== "undefined" &&
  typeof window.document.createElement !== "undefined"
    ? useLayoutEffect
    : useEffect;

const useGetter = <T>(current: T) => {
  const ref = useRef(current);
  useIsomorphicLayoutEffect(() => {
    ref.current = current;
  });
  return () => ref.current;
};

// See: https://github.com/slorber/react-async-hook/commit/0e34040d98ac241ff2e5f1e423a0d5bb2f5ea1ad#diff-a2a171449d862fe29692ce031981047d7ab755ae7f84c707aef80701b3ea0c80L397
export default function useAsyncFetchMore<R, Args extends any[]>({
  value,
  fetchMore,
  merge,
  isEnd: isEndFn,
}: {
  value: UseAsyncReturn<R, Args>;
  fetchMore: (result: R) => Promise<R>;
  merge: (result: R, moreResult: R) => R;
  isEnd: (moreResult: R) => boolean;
}) {
  const getAsyncValue = useGetter(value);
  const [isEnd, setIsEnd] = useState(false);

  // TODO: Should find a way to support cancellation!
  const fetchMoreId = useRef(0);

  const fetchMoreAsync = useAsyncCallback(async () => {
    const freshAsyncValue = getAsyncValue();
    if (freshAsyncValue.status !== "success") {
      throw new Error("Can't fetch more if the original fetch is not a success");
    }
    if (fetchMoreAsync.status === "loading") {
      throw new Error("Can't fetch more, because we are already fetching more!");
    }

    fetchMoreId.current += fetchMoreId.current;
    const currentId = fetchMoreId.current;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const moreResult = await fetchMore(freshAsyncValue.result!);

    // TODO: We should just use "freshAsyncValue === getAsyncValue()" but asyncValue is not "stable"
    const isStillSameValue =
      freshAsyncValue.status === getAsyncValue().status && freshAsyncValue.result === getAsyncValue().result;

    const isStillSameId = fetchMoreId.current === currentId;

    // Handle race conditions: we only merge the fetchMore result if the initial async value is the same
    const canMerge = isStillSameValue && isStillSameId;
    if (canMerge) {
      value.merge({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        result: merge(value.result!, moreResult),
      });
      if (isEndFn(moreResult)) {
        setIsEnd(true);
      }
    }

    // Return is useful for chaining, like fetchMore().then(result => {});
    return moreResult;
  });

  const reset = () => {
    fetchMoreAsync.reset();
    setIsEnd(false);
  };

  // We only allow to fetch more on a stable async value
  // If that value change for whatever reason, we reset the fetchmore too (which will make current pending requests to be ignored)
  // TODO value is not stable, we could just reset on value change otherwise
  const shouldReset = value.status !== "success";
  useEffect(() => {
    if (shouldReset) {
      reset();
    }
  }, [shouldReset]);

  return {
    canFetchMore: value.status === "success" && fetchMoreAsync.status !== "loading",
    loading: fetchMoreAsync.loading,
    status: fetchMoreAsync.status,
    fetchMore: fetchMoreAsync.execute,
    isEnd,
  };
}
