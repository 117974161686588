import * as React from "react";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import classNames from "components/ui/classNames";

const linkAnchorButtonVariants = cva(
  classNames(
    "inline-box pointer-events-auto m-0 box-border cursor-pointer select-none appearance-none",
    "overflow-visible rounded-[1px] border-none p-0 text-sm no-underline",
    "transition-all duration-75 ease-in-out",
    "active:shadow-none",
    "disabled:pointer-events-none disabled:cursor-default",
  ),
  {
    variants: {
      variant: {
        primary: "text-brand hover:underline focus:shadow-[0_0_0_2px_#d1d9fa] focus:outline-none",
        success: "text-[#39896f] hover:underline focus:shadow-[0_0_0_2px_#6dd0b0] focus:outline-none",
        danger: "text-red-700 hover:underline focus:shadow-[0_0_0_2px_#e3a5a5] focus:outline-none",
        warning: "text-[#de911d] hover:underline focus:shadow-[0_0_0_2px_#fadb5f] focus:outline-none",
        black: "text-[#333333] hover:underline focus:shadow-[0_0_0_2px_#829ab1] focus:outline-none",
        disabled: "text-[#829ab1] focus:outline-none",
      },
    },
    defaultVariants: {
      variant: "primary",
    },
  },
);

interface LinkAnchorButtonProps
  extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
    VariantProps<typeof linkAnchorButtonVariants> {
  disabled?: boolean;
}

const LinkAnchorButton = React.forwardRef<HTMLAnchorElement, LinkAnchorButtonProps>(
  ({ variant, children, disabled, className, ...props }, ref) => {
    return (
      <a
        ref={ref}
        aria-disabled={disabled}
        className={classNames(linkAnchorButtonVariants({ variant }), className)}
        {...props}
      >
        {children}
      </a>
    );
  },
);

export default LinkAnchorButton;
