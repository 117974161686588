/* eslint-disable formatjs/enforce-placeholders */
/* eslint-disable no-nested-ternary */
import { useQuery } from "@apollo/client";
import TwoPassRender from "components/shared/TwoPassRender";
import classNames from "components/ui/classNames";
import differenceInMilliseconds from "date-fns/differenceInMilliseconds";
import fromUnixTime from "date-fns/fromUnixTime";
import getUnixTime from "date-fns/getUnixTime";
import { useAmplitude } from "lib/amplitude/Amplitude";
import CommittedRevisionsQuery from "lib/queries/CommittedRevisionsQuery";
import type {
  ICommittedRevisionsQuery,
  ICommittedRevisionsQueryVariables,
} from "lib/queries/__generated__/CommittedRevisionsQuery.generated";
import ms from "ms";
import Head from "next/head";
import { useQueryState } from "nuqs";
import { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useLocalStorage } from "usehooks-ts";

type Props = {
  delayBetweenRefetches: number;
  timeBetweenFlakyUpdateAttempts: number;
  cacheControlBustingWindow: number;
};

export default function AppUpdateBanner({
  delayBetweenRefetches = ms("30s"),
  timeBetweenFlakyUpdateAttempts = ms("1h"),
  cacheControlBustingWindow = ms("2m"),
}: Partial<Props>) {
  const [cacheBusterQueryString] = useQueryState("_");
  const amplitude = useAmplitude();
  const isBustingCache = getShouldRenderCacheBustEquivs(cacheBusterQueryString, cacheControlBustingWindow);
  useEffect(() => {
    if (isBustingCache) {
      amplitude.logEvent("Brutally cache busting");
    }
  }, []);

  return (
    <>
      <TwoPassRender>
        {() => (
          <TwoPassBanner
            delayBetweenRefetches={delayBetweenRefetches}
            timeBetweenFlakyUpdateAttempts={timeBetweenFlakyUpdateAttempts}
            cacheControlBustingWindow={cacheControlBustingWindow}
          />
        )}
      </TwoPassRender>
      <Head>
        {isBustingCache && <meta key="Cache-control" httpEquiv="Cache-control" content="no-cache" />}
        {isBustingCache && <meta key="Expires" httpEquiv="Expires" content="-1" />}
      </Head>
    </>
  );
}

function TwoPassBanner({ delayBetweenRefetches, timeBetweenFlakyUpdateAttempts, cacheControlBustingWindow }: Props) {
  const { availableUpdate, requestUpdateAttempt } = useCommittedRevisions({
    delayBetweenRefetches,
    timeBetweenFlakyUpdateAttempts,
    cacheControlBustingWindow,
  });
  if (!availableUpdate) {
    return null;
  }
  const requestUpdate = (
    <button
      type="button"
      onClick={() => requestUpdateAttempt()}
      className={classNames(
        "rounded-full bg-orange-950/30 px-2 py-0.5 text-sm tracking-wider",
        "hover:bg-slate-800 hover:text-white",
        "dark:hover:bg-blue-600 dark:hover:text-white",
        "dark:bg-blue-600/30 dark:text-blue-300",
      )}
    >
      <FormattedMessage defaultMessage="Clique para atualizar" id="KyIIyP" />
    </button>
  );
  const updateAvailable = (
    <FormattedMessage id="nx9IBG" defaultMessage="Fala Investidor! Uma nova atualização está disponível para o site." />
  );
  return (
    <div className="w-full border-b bg-orange-600 text-white dark:border-slate-950 dark:bg-orange-600/10 dark:text-orange-200">
      <p className="px-6 py-4 text-left text-base font-medium leading-6 sm:p-2 sm:text-center">
        {updateAvailable} {requestUpdate}
      </p>
    </div>
  );
}

export function getShouldRenderCacheBustEquivs(timeStamp: string | null, cacheControlBustingWindow: number = ms("2m")) {
  if (!timeStamp) {
    return false;
  }
  const timeSinceUpdateAttempt = differenceInMilliseconds(Date.now(), parseInt(timeStamp, 10));
  // Try bust cache for 2 minutes prior to clearing the URL and stopping the http-equiv's on document
  return timeSinceUpdateAttempt < cacheControlBustingWindow;
}

type UpdateAttemptRequest = {
  sha: string;
  authoredDate: string;
  timeStamp: number;
};
type CommittedRevision = ICommittedRevisionsQuery["committedRevisions"][0];
function useCommittedRevisions({
  delayBetweenRefetches,
  timeBetweenFlakyUpdateAttempts,
  cacheControlBustingWindow,
}: Props) {
  const isServer = typeof window === "undefined";
  const lastFetchDate = useRef<number>(Date.now());
  const currentRevision = process.env.GIT_SHA_LONG || "";
  const [lastUpdateAttempt, setLastUpdateAttempt] = useLocalStorage<UpdateAttemptRequest | null>(
    "@fundamentei/lastUpdateAttempt",
    null,
    {
      serializer: JSON.stringify,
      deserializer: JSON.parse,
    },
  );
  const environment = process.env.FUNDAMENTEI_ENV || "development";
  const [availableUpdate, setAvailableUpdate] = useState<CommittedRevision | null>(null);
  const [cacheBusterQueryString, setCacheBustTimeStamp] = useQueryState("_");
  const isPastBustingWindow = getShouldRenderCacheBustEquivs(cacheBusterQueryString, cacheControlBustingWindow);

  const { refetch } = useQuery<ICommittedRevisionsQuery, ICommittedRevisionsQueryVariables>(CommittedRevisionsQuery, {
    refetchWritePolicy: "overwrite",
    nextFetchPolicy: "no-cache",
    partialRefetch: true,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
    onCompleted(data) {
      const justTriedToUpdate =
        lastUpdateAttempt &&
        timeBetweenFlakyUpdateAttempts > Date.now() - fromUnixTime(lastUpdateAttempt.timeStamp).getTime();
      // Don't offer any banners if the user just tried to update and it's been flaky to pick up the latest version
      if (justTriedToUpdate) {
        setAvailableUpdate(null);
        return;
      }

      const revisionAtIndex = data.committedRevisions.findIndex((candidate) => {
        return candidate.sha === currentRevision;
      });
      if (data.committedRevisions.length > 0 && revisionAtIndex > 0 && revisionAtIndex !== 0) {
        const updateToRevision = data.committedRevisions[0];
        setAvailableUpdate(updateToRevision);
      }
    },
    variables: {
      // Check on `master` branch for production
      branch: environment === "production" ? "master" : "qa",
    },
  });

  const handleWindowInteraction = async () => {
    // Check if was refetched in the last 30 seconds
    if (lastFetchDate.current && Date.now() - lastFetchDate.current < delayBetweenRefetches) {
      return;
    }
    lastFetchDate.current = Date.now();
    await refetch({});
  };

  useEffect(() => {
    if (!isPastBustingWindow && cacheBusterQueryString) {
      // Clear out the URL and free the cache. We can't be sure the update have worked...
      setCacheBustTimeStamp(null);
    }

    const isStaleUpdateAttempt =
      lastUpdateAttempt === null || !lastUpdateAttempt.timeStamp
        ? false
        : Date.now() - fromUnixTime(lastUpdateAttempt.timeStamp).getTime() > timeBetweenFlakyUpdateAttempts;
    if (isStaleUpdateAttempt) {
      // Just so it can be offered again if the reload didn't cut it
      setLastUpdateAttempt(null);
    }

    window.addEventListener("focus", handleWindowInteraction);
    window.addEventListener("blur", handleWindowInteraction);
    return () => {
      window.removeEventListener("focus", handleWindowInteraction);
      window.removeEventListener("blur", handleWindowInteraction);
    };
  }, []);
  return {
    availableUpdate: isPastBustingWindow ? null : availableUpdate,
    requestUpdateAttempt: () => {
      if (!availableUpdate || isPastBustingWindow) {
        return;
      }
      setLastUpdateAttempt({
        sha: availableUpdate.sha,
        authoredDate: availableUpdate.authoredDate,
        timeStamp: getUnixTime(new Date()),
      });
      if (!isServer && availableUpdate) {
        setCacheBustTimeStamp(Date.now().toString());
        // Wait a bit before reloading...just so we can stick the cache bust tag to the URL
        setTimeout(() => {
          // @ts-ignore
          window.location.reload(true);
        }, 100);
      }
    },
  };
}
