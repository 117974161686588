/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/no-empty-interface */
import classNames from "components/ui/classNames";
import isDarkModeSupportedPage from "components/ux/isDarkModeSupportedPage";
import type { LinkProps } from "next/link";
import Link from "next/link";
import { useQueryState } from "nuqs";
import type { ReactNode } from "react";

interface MobileNavbarLinkProps extends LinkProps {
  href: string;
  children: ReactNode;
  className?: string;
  target?: string;
}
export default function MobileNavbarLink({ href, children, className, target, ...props }: MobileNavbarLinkProps) {
  const [theme] = useQueryState("theme");
  // eslint-disable-next-line no-underscore-dangle
  const keepTheme = theme !== null && isDarkModeSupportedPage(href);
  if (target === "_blank") {
    return (
      <a
        href={keepTheme ? `${href}?theme=${theme}` : href}
        target="_blank"
        rel="noopener"
        className={classNames(
          "px-4 py-4",
          "text-sm tracking-wide text-gray-600 hover:text-gray-400 dark:font-medium dark:text-slate-300",
          "focus:outline-none",
          className,
        )}
        {...props}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      href={keepTheme ? `${href}?theme=${theme}` : href}
      passHref={true}
      className={classNames(
        "px-4 py-4",
        "text-sm tracking-wide text-gray-600 hover:text-gray-400 dark:font-medium dark:text-slate-300",
        "focus:outline-none",
        className,
      )}
      {...props}
    >
      {children}
    </Link>
  );
}
