import * as React from "react";
import classNames from "components/ui/classNames";
import { FormattedMessage } from "react-intl";

interface ILoadMoreNotificationsButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  loading: boolean;
}

export default function LoadMoreNotificationsButton({ loading, ...props }: ILoadMoreNotificationsButtonProps) {
  return (
    <button
      type="button"
      className={classNames(
        "m-0 inline-flex cursor-pointer select-none appearance-none justify-center",
        "border-none bg-transparent p-0 font-aeonik text-sm font-medium tracking-wide text-blue-700",
        "hover:underline focus:outline-none",
        "disabled:pointer-events-none disabled:text-[#394e66]",
      )}
      {...props}
    >
      {loading ? (
        <FormattedMessage defaultMessage="Carregando..." id="EMLr/e" />
      ) : (
        <FormattedMessage defaultMessage="Carregar mais notificações" description="Notificações" id="cN0nRh" />
      )}
    </button>
  );
}
