import type { Activity, User } from "../types";

// The main purpose of this function is to safely extract all the actors from activities without duplicating and keeping
// the most recent activity from a given actor. Say that "Andrey" interacted two times, his name should only pop up
// once but the order that it will appear will depend on the order that the activity happened, for example:
// 1. Andrey commented
// 2. Eduardo commented
// 3. Giovanni commented
// 4. Andrey commented
// The desired output will be "Eduardo, Giovanni and Andrey commented" instead of "Andrey, Eduardo and Giovanni"
export default function pullActorsFromActivities(activities: Activity[]): User[] {
  return activities.reduce<User[]>((actors, activity) => {
    return actors
      .filter((actor) => {
        return actor.id !== activity.actor.id;
      })
      .concat(activity.actor);
  }, []);
}
