import type { NotificationsUnion } from "components/engagement/notifications/types";
import classNames from "components/ui/classNames";
import * as React from "react";

interface NotificationListItemProps extends React.HTMLAttributes<HTMLLIElement> {
  notification: NotificationsUnion;
}

export default function NotificationListItem({ notification, children, ...props }: NotificationListItemProps) {
  return (
    <li
      className={classNames(
        "group box-border grid grid-cols-[42px_1fr] gap-x-3 px-4 py-3",
        "border-l-2 border-transparent hover:bg-purple-50/80 dark:hover:bg-slate-900",
        {
          "border-l-blue-600 bg-blue-50 dark:border-l-orange-200 dark:bg-orange-50/10 dark:hover:bg-orange-950/20":
            !notification.is_read,
        },
      )}
      {...props}
    >
      {children}
    </li>
  );
}
