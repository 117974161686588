"use client";

import * as SelectPrimitive from "@radix-ui/react-select";
import { CheckCircle2, CheckIcon, ChevronDownIcon } from "lucide-react";
import * as React from "react";
import classNames from "./classNames";

const Select = SelectPrimitive.Root;

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger> & {
    hideChevronIcon?: boolean;
  }
>(({ className, children, hideChevronIcon, asChild, ...props }, ref) => {
  return (
    <SelectPrimitive.Trigger
      ref={ref}
      asChild={asChild}
      className={classNames(
        "group flex h-10 w-full items-center justify-between space-x-2 rounded-md px-3 py-2",
        "border border-slate-300 bg-transparent text-sm placeholder:text-slate-400",
        "focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-300 focus-visible:ring-offset-2",
        "disabled:cursor-not-allowed disabled:opacity-50",
        "dark:border-slate-700 dark:text-slate-50 dark:focus:ring-slate-400 dark:focus:ring-offset-slate-900",
        className,
      )}
      {...props}
    >
      {children}
      {!hideChevronIcon && (
        <ChevronDownIcon
          className="-ml-px h-4 w-4 text-current opacity-50 group-hover:opacity-100"
          absoluteStrokeWidth={true}
          strokeWidth={2}
        />
      )}
    </SelectPrimitive.Trigger>
  );
});

SelectTrigger.displayName = SelectPrimitive.Trigger.displayName;

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content> & {
    scrollAreaClassName?: string;
    scrollAreaViewportClassName?: string;
  }
>(({ className, scrollAreaViewportClassName, position = "popper", side = "bottom", children, ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      side={side}
      position={position}
      className={classNames(
        "relative z-50 min-w-[8rem] overflow-hidden rounded-md",
        "border border-slate-100 bg-white text-slate-700 shadow-md animate-in fade-in-80",
        "dark:border-slate-800 dark:bg-slate-800 dark:text-slate-400",
        {
          "data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1":
            position === "popper",
        },
        className,
      )}
      {...props}
    >
      <SelectPrimitive.Viewport
        className={classNames("max-h-96 p-1", scrollAreaViewportClassName, {
          "h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]":
            position === "popper",
        })}
      >
        {children}
      </SelectPrimitive.Viewport>
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
));

SelectContent.displayName = SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label
    ref={ref}
    className={classNames("py-1.5 pl-8 pr-2 text-sm font-semibold text-slate-900 dark:text-slate-300", className)}
    {...props}
  />
));

SelectLabel.displayName = SelectPrimitive.Label.displayName;

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={classNames(
      "relative flex cursor-default select-none items-center",
      "rounded-sm py-1.5 pl-8 pr-2 font-aeonik text-sm font-medium outline-none",
      "data-[disabled]:pointer-events-none data-[disabled]:opacity-50 focus:bg-slate-100",
      "dark:focus:bg-slate-700",
      className,
    )}
    {...props}
  >
    <span className="absolute left-2 flex h-3.5 w-3.5 items-center justify-center">
      <SelectPrimitive.ItemIndicator>
        <CheckIcon className="h-4 w-4" />
      </SelectPrimitive.ItemIndicator>
    </span>

    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
));

SelectItem.displayName = SelectPrimitive.Item.displayName;

const FatSelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={classNames(
      "relative flex cursor-default select-none items-center",
      "rounded-sm px-3 py-2 font-aeonik text-sm font-medium outline-none",
      "data-[disabled]:pointer-events-none data-[disabled]:opacity-50 focus:bg-slate-100",
      "dark:focus:bg-slate-950",
      "group",
      className,
    )}
    {...props}
  >
    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
    <span className="absolute right-2 ml-2 flex h-5 w-5 items-center justify-center">
      <SelectPrimitive.ItemIndicator>
        <CheckCircle2 strokeWidth={2} className="h-5 w-5 fill-emerald-50/50 text-emerald-600 dark:fill-transparent" />
      </SelectPrimitive.ItemIndicator>
    </span>
  </SelectPrimitive.Item>
));

FatSelectItem.displayName = SelectPrimitive.Item.displayName;

const PrimitiveSelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={classNames(
      "relative flex cursor-default select-none items-center",
      "rounded-xl px-3 py-2 font-aeonik text-sm font-medium outline-none",
      "data-[disabled]:pointer-events-none data-[disabled]:opacity-50 focus:bg-slate-100",
      "dark:focus:bg-slate-950",
      "group",
      className,
    )}
    {...props}
  >
    <SelectPrimitive.ItemText asChild={true}>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
));

PrimitiveSelectItem.displayName = SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    className={classNames("-mx-1 my-1 h-px bg-slate-100 dark:bg-slate-700", className)}
    {...props}
  />
));

SelectSeparator.displayName = SelectPrimitive.Separator.displayName;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface NativeSelectProps extends React.InputHTMLAttributes<HTMLSelectElement> {}

function NativeSelect({ className, ...props }: NativeSelectProps) {
  return (
    <select
      className={classNames([
        "flex select-none rounded-md border-gray-300 text-base",
        "focus-visible:outline-none focus-visible:ring-1 dark:border-slate-950 dark:bg-slate-800 dark:text-white",
        className,
      ])}
      {...props}
    />
  );
}

export {
  FatSelectItem,
  NativeSelect,
  PrimitiveSelectItem,
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
};
