import classNames from "components/ui/classNames";
import * as React from "react";

interface INativeSelectProps
  extends React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement> {
  width?: any;
  height?: 16 | 20 | 24 | 28 | 32 | 36 | 40 | 44 | 48;
  children: any;
}

export default function NativeSelect({ height = 32, children, disabled, style, ...props }: INativeSelectProps) {
  return (
    <select
      className={classNames(
        "pointer-events-auto relative flex w-full cursor-pointer appearance-none",
        "rounded-lg border border-slate-300 py-0 pl-3 pr-9 text-sm tracking-wide shadow-sm outline-none",
        "disabled:pointer-events-none disabled:bg-slate-50",
        "dark:border-slate-950 dark:bg-slate-900 dark:text-slate-300",
      )}
      disabled={disabled}
      style={{
        height,
        ...style,
      }}
      {...props}
    >
      {children}
    </select>
  );
}
