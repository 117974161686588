import NotificationListItem from "components/engagement/notifications/items/NotificationListItem";
import type {
  ReactedToCommentNotification,
  ReactedToReplyNotification,
} from "components/engagement/notifications/types";
import * as React from "react";
import { useIntl } from "react-intl";
import Avatar from "../../../core/Avatar";
import CommentTime from "../../../discussions/CommentTime";
import NotificationActors from "../elements/NotificationActors";
import NotificationContentParagraph from "../elements/NotificationContentParagraph";
import SafeActorName from "../elements/SafeActorName";
import LinkToComment from "../elements/links/LinkToComment";
import getActivityTimeAsUtc from "../utils/getActivityTimeAsUtc";
import pullActorsFromActivities from "../utils/pullActorsFromActivities";
import safeCommentOrReplyPreamble from "../utils/safeCommentOrReplyPreamble";

interface IReactedToCommentOrReplyNotificationListItemProps extends React.HTMLAttributes<HTMLLIElement> {
  notification: ReactedToCommentNotification | ReactedToReplyNotification;
}

export default function ReactedToCommentOrReplyNotificationListItem({
  notification,
}: IReactedToCommentOrReplyNotificationListItemProps) {
  const intl = useIntl();

  // Shouldn't happen but just making sure so it doesn't break when people are using it.
  if (notification.activities.length === 0) {
    return null;
  }

  const topActivity = notification.activities[0];
  if (topActivity.reaction !== "THUMBS_UP") {
    return null;
  }

  const thumbsUpSentence = intl.formatMessage(
    {
      defaultMessage: "{actorCount, plural, one {curtiu seu comentário} other {curtiram seu comentário}}",
      description: "Notificações",
      id: "AnOVqY",
    },
    {
      actorCount: notification.actor_count,
    },
  );
  const replyPreamble = safeCommentOrReplyPreamble(topActivity.object.data.bodyAsPlainText);

  const actors = (
    <NotificationActors
      maximumActorsConjunction={3}
      actors={pullActorsFromActivities(notification.activities).map((actor) => {
        return <SafeActorName key={actor.id} firstName={actor.data.firstName} />;
      })}
    />
  );

  return (
    <NotificationListItem notification={notification}>
      <Avatar
        src={topActivity.actor.data.pictureUrl}
        hashValue={topActivity.actor.id}
        alt={topActivity.actor.data.firstName}
        size={42}
        squircle={true}
      />

      <div className="grid w-72 gap-y-2 lg:w-full">
        <NotificationContentParagraph>
          <LinkToComment activity={topActivity}>
            {actors} {thumbsUpSentence} <i>{`"${replyPreamble}"`}</i>
          </LinkToComment>
        </NotificationContentParagraph>

        <div className="text-right">
          <LinkToComment activity={topActivity}>
            <CommentTime timeStamp={getActivityTimeAsUtc(topActivity.time)} />
          </LinkToComment>
        </div>
      </div>
    </NotificationListItem>
  );
}
