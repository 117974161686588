/* eslint-disable no-nested-ternary */
import { parseAsStringLiteral, useQueryState } from "nuqs";
import { ThemeProvider } from "next-themes";
import { usePathname } from "next/navigation";

import AppUpdateBanner from "components/layouts/AppUpdateBanner";
import Navbar from "components/navbar/Navbar";
import isDarkModeSupportedPage from "components/ux/isDarkModeSupportedPage";

import useSelfie from "lib/hooks/useSelfie";
import { IUserSettingsV2Theme } from "types/graphqlTypes";
import TrialStickyBanner from "components/billing/TrialStickyBanner";

interface NavbarLayoutProps {
  forcedTheme?: "light" | "dark";
  hardRefresh?: boolean;
  disableStickyNavbar?: boolean;
  onlyChildren?: boolean;
  children: any;
}

export default function NavbarLayout({
  forcedTheme,
  hardRefresh,
  disableStickyNavbar = false,
  onlyChildren,
  children,
}: NavbarLayoutProps) {
  const viewer = useSelfie();
  const pathname = usePathname();
  const [currentThemeFromUrl] = useQueryState("theme", parseAsStringLiteral(["light", "dark"]).withDefault("light"));

  return (
    <ThemeProvider
      forcedTheme={(() => {
        if (forcedTheme) {
          return forcedTheme;
        }
        // We're not there yet
        if (!viewer && pathname === "/") {
          return "light";
        }
        if (!isDarkModeSupportedPage(pathname)) {
          return "light";
        }

        if (viewer) {
          return viewer?.settingsV2.theme === IUserSettingsV2Theme.Dark ? "dark" : "light";
        }

        return currentThemeFromUrl;
      })()}
      enableSystem={false}
      attribute="class"
      disableTransitionOnChange={true}
    >
      {onlyChildren ? (
        children
      ) : (
        <>
          <Navbar hardRefresh={hardRefresh} disableStickyNavbar={disableStickyNavbar} />
          {process.env.NODE_ENV === "production" && <AppUpdateBanner />}
          {viewer?.isTrialing && pathname !== "/thank-you" && <TrialStickyBanner />}
          {children}
        </>
      )}
    </ThemeProvider>
  );
}
