import gql from "graphql-tag";

export default gql`
  query CommittedRevisionsQuery($branch: ID = "master") {
    committedRevisions(repository: "web", branch: $branch) {
      sha
      repository
      authoredDate
    }
  }
`;
