import gql from "graphql-tag";

export default gql`
  mutation FinishSubscriptionTrial($stripeSubscriptionId: ID) {
    finishSubscriptionTrial(stripeSubscriptionId: $stripeSubscriptionId) {
      id
      status
    }
  }
`;
