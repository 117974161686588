import differenceInMinutes from "date-fns/differenceInMinutes";
import differenceInMonths from "date-fns/differenceInMonths";
import differenceInSeconds from "date-fns/differenceInSeconds";
import format from "date-fns/format";
import formatDistanceStrict from "date-fns/formatDistanceStrict";
import ptBR from "date-fns/locale/pt-BR";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

interface ICommentTimeProps {
  timeStamp: number | Date;
}

export default function CommentTime({ timeStamp }: ICommentTimeProps) {
  const intl = useIntl();

  const dateTime = new Date(timeStamp);

  const [, tick] = useState<number>(0);

  useEffect(() => {
    const distanceInMinutes = differenceInMinutes(new Date(), dateTime);

    let timeoutMs = 2 * 60; // 2 minutes

    if (distanceInMinutes > 60) {
      timeoutMs = 4 * 60; // every 4 minutes
    } else if (distanceInMinutes > 120) {
      timeoutMs = 6 * 60; // every 6 minutes
    } else if (distanceInMinutes <= 0) {
      timeoutMs = 30; // 10 seconds
    }

    const scheduledInterval = setInterval(() => tick(Date.now()), timeoutMs * 1000);

    return () => clearInterval(scheduledInterval);
  }, [timeStamp]);

  const distanceInMonths = differenceInMonths(new Date(), dateTime);

  if (distanceInMonths > 3) {
    return (
      <time
        suppressHydrationWarning={true}
        dateTime={dateTime.toISOString()}
        title={intl.formatDate(dateTime, {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        })}
        className="text-sm font-medium tracking-wide text-slate-400 dark:text-slate-500 dark:group-hover:text-inherit"
      >
        {format(dateTime, "d 'de' MMMM, yyyy", {
          locale: ptBR,
        })}
      </time>
    );
  }

  const distanceInSeconds = differenceInSeconds(new Date(), dateTime);
  const timeDistance = formatDistanceStrict(new Date(), dateTime, {
    locale: ptBR,
  });

  return (
    <time
      suppressHydrationWarning={true}
      dateTime={dateTime.toISOString()}
      title={intl.formatDate(dateTime, {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      })}
      className="text-sm font-medium tracking-wide text-slate-400 dark:text-slate-500 dark:group-hover:text-inherit"
    >
      {distanceInSeconds < 30 ? (
        <FormattedMessage defaultMessage="agora mesmo" id="LMZrJg" description="Notificações" />
      ) : (
        <FormattedMessage
          defaultMessage="há {timeDistance}"
          values={{
            timeDistance,
          }}
          id="1Jx9Fc"
        />
      )}
    </time>
  );
}
