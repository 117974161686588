import * as palette from "./palette";

type TStandardShadeWithVariants = {
  lightest: string;
  darkest: string;
  variants: [string, string, string, string, string, string, string, string, string, string];
};

type TShades = {
  brand: string;
  black: [string, string];
  link: {
    default: string;
    visited: string;
  };
  primary: TStandardShadeWithVariants;
  greys: TStandardShadeWithVariants;
  accents: {
    green: TStandardShadeWithVariants;
    golden: TStandardShadeWithVariants;
    yellow: TStandardShadeWithVariants;
    red: TStandardShadeWithVariants;
  };
  rainbow: {
    blue: TStandardShadeWithVariants;
    green: TStandardShadeWithVariants;
  };
};

export const shades: TShades = {
  brand: palette.royalBlue,
  black: [palette.mineShaft, palette.tundora],
  link: {
    default: palette.link,
    visited: palette.linkVisited,
  },
  primary: {
    darkest: palette.biscay,
    lightest: palette.selago,
    variants: [
      palette.biscay,
      palette.bayOfMany,
      palette.persianBlue,
      palette.mariner,
      palette.royalBlue,
      palette.cornflowerBlue,
      palette.portage,
      palette.perano,
      palette.tropicalBlue,
      palette.selago,
    ],
  },
  greys: {
    darkest: palette.bigStone,
    lightest: palette.catskillWhite,
    variants: [
      palette.bigStone,
      palette.pickledBluewood,
      palette.fiord,
      palette.blueBayoux,
      palette.lynch,
      palette.baliHai,
      palette.rockBlue,
      palette.heather,
      palette.mystic,
      palette.catskillWhite,
    ],
  },
  accents: {
    green: {
      lightest: palette.whiteIce,
      darkest: palette.tePapaGreen,
      variants: [
        palette.tePapaGreen,
        palette.stromboli,
        palette.amazon,
        palette.viridian,
        palette.oceanGreen,
        palette.keppel,
        palette.downy,
        palette.riptide,
        palette.magicMint,
        palette.whiteIce,
      ],
    },
    golden: {
      darkest: palette.punga,
      lightest: palette.linen,
      variants: [
        palette.punga,
        palette.kumera,
        palette.luxorGold,
        palette.tussock,
        palette.roti,
        palette.tacha,
        palette.calico,
        palette.zombie,
        palette.chamois,
        palette.linen,
      ],
    },
    yellow: {
      darkest: palette.peanut,
      lightest: palette.butteryWhite,
      variants: [
        palette.peanut,
        palette.paarl,
        palette.copper,
        palette.dixie,
        palette.fuelYellow,
        palette.casablanca,
        palette.energyYellow,
        palette.sweetCorn,
        palette.eggWhite,
        palette.butteryWhite,
      ],
    },
    red: {
      darkest: palette.espresso,
      lightest: palette.vanillaIce,
      variants: [
        palette.espresso,
        palette.crownOfThorns,
        palette.burntUmber,
        palette.stiletto,
        palette.mojo,
        palette.chesnutRose,
        palette.newYorkPink,
        palette.tonysPink,
        palette.shilo,
        palette.vanillaIce,
      ],
    },
  },
  rainbow: {
    blue: {
      darkest: palette.downriver,
      lightest: palette.lilyWhite,
      variants: [
        palette.downriver,
        palette.chathamsBlue,
        palette.funBlue,
        palette.cobalt,
        palette.scienceBlue,
        palette.curiousBlue,
        palette.pictonBlue,
        palette.malibu,
        palette.frenchPass,
        palette.lilyWhite,
      ],
    },
    green: {
      darkest: palette.zuccini,
      lightest: palette.grannyApple,
      variants: [
        palette.zuccini,
        palette.darkFern,
        palette.forestGreen,
        palette.seaGreen,
        palette.goblin,
        palette.fruitSalad,
        palette.deYork,
        palette.mossGreen,
        palette.fringyFlower,
        palette.grannyApple,
      ],
    },
  },
};

export default palette;
