import { useMutation } from "@apollo/client";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "components/ui/AlertDialog";
import { useAmplitude } from "lib/amplitude/Amplitude";
import type {
  IFinishSubscriptionTrialMutation,
  IFinishSubscriptionTrialMutationVariables,
} from "lib/queries/__generated__/FinishSubscriptionTrial.generated";
import FinishSubscriptionTrial from "lib/queries/FinishSubscriptionTrial";
import type { ReactNode } from "react";
import toast from "react-hot-toast";
import { FormattedMessage, useIntl } from "react-intl";
import { IStripeSubscriptionStatus } from "types/graphqlTypes";

interface ActivatePremiumDialogProps {
  children(state: { isLoading: boolean }): ReactNode;
}

export default function ActivatePremiumDialog({ children }: ActivatePremiumDialogProps) {
  const intl = useIntl();
  const amplitude = useAmplitude();

  const [finishSubscriptionTrial, { loading }] = useMutation<
    IFinishSubscriptionTrialMutation,
    IFinishSubscriptionTrialMutationVariables
  >(FinishSubscriptionTrial, {
    onCompleted: ({ finishSubscriptionTrial }) => {
      if (finishSubscriptionTrial?.status === IStripeSubscriptionStatus.Active) {
        window.location.href = "/thank-you";
      }
    },
  });

  return (
    <div className="mx-auto py-2 text-center">
      <AlertDialog>
        <AlertDialogTrigger asChild={true}>
          {children({
            isLoading: loading,
          })}
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              <FormattedMessage defaultMessage="Você está prestes a ativar sua assinatura..." id="ByYvqK" />
            </AlertDialogTitle>
            <AlertDialogDescription>
              <FormattedMessage
                defaultMessage="Essa decisão não poderá ser desfeita. Após confirmar, o valor da assinatura será cobrado no seu cartão e você terá acesso Premium completo no Fundamentei!"
                id="baH4D+"
              />
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>
              <FormattedMessage defaultMessage="Cancelar" id="nZLeaQ" />
            </AlertDialogCancel>
            <AlertDialogAction
              onClick={() => {
                amplitude.logEvent("Clicked on Activate Subscription button");
                toast.promise(finishSubscriptionTrial({}), {
                  loading: intl.formatMessage({
                    defaultMessage: "Ativando...",
                    id: "/IluYm",
                  }),
                  success: intl.formatMessage({
                    defaultMessage: "Assinatura Premium ativada com sucesso!",
                    id: "8eVnQG",
                  }),
                  error: intl.formatMessage({
                    defaultMessage:
                      "Não foi possível ativar sua assinatura Premium. Entre em contato para mais detalhes.",
                    id: "9hiHax",
                  }),
                });
              }}
            >
              <FormattedMessage defaultMessage="Ativar Assinatura" id="sLa+rf" />
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  );
}
