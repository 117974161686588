import classNames from "components/ui/classNames";
import type { GetHandleProps, GetRailProps, GetTrackProps, SliderItem } from "react-compound-slider";
import { Handles, Rail, Slider, Ticks, Tracks } from "react-compound-slider";

interface IRangeSliderProps {
  domain: [number, number];
  values: ReadonlyArray<number>;
  labels?: [string, string];
  disabled?: boolean;
  tickValues?: number[];
  formatTick?: (value: number) => string;
  onChange: (values: ReadonlyArray<number>) => void;
  onUpdate?: (values: ReadonlyArray<number>) => void;
}

export default function RangeSlider({
  domain,
  values,
  disabled = false,
  labels,
  tickValues,
  formatTick,
  onUpdate,
  onChange,
}: IRangeSliderProps) {
  return (
    <Slider
      mode={2}
      step={1}
      domain={domain}
      values={values}
      rootStyle={{
        position: "relative",
        width: "100%",
      }}
      disabled={disabled}
      onChange={onChange}
      onUpdate={onUpdate}
    >
      <Rail>{({ getRailProps }) => <SliderRail disabled={disabled} getRailProps={getRailProps} />}</Rail>

      <Handles>
        {({ handles, getHandleProps }) => (
          <div>
            {handles.map((handle, index) => {
              const labelForHandle = Array.isArray(labels) ? labels[index] : null;

              return (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  disabled={disabled}
                  aria-label={labelForHandle}
                  getHandleProps={getHandleProps}
                />
              );
            })}
          </div>
        )}
      </Handles>

      <Tracks left={false} right={false}>
        {({ tracks, getTrackProps }) => (
          <div>
            {tracks.map(({ id, source, target }) => {
              return (
                <Track key={id} source={source} target={target} disabled={disabled} getTrackProps={getTrackProps} />
              );
            })}
          </div>
        )}
      </Tracks>

      {formatTick && (
        <Ticks values={tickValues}>
          {({ ticks }) => (
            <div>
              {ticks.map((tick) => (
                <Tick key={tick.id} tick={tick} count={ticks.length} format={formatTick} />
              ))}
            </div>
          )}
        </Ticks>
      )}
    </Slider>
  );
}

interface ISliderRail {
  disabled: boolean;
  getRailProps: GetRailProps;
}

function SliderRail({ disabled, getRailProps }: ISliderRail) {
  return (
    <>
      <div
        aria-disabled={disabled}
        className="absolute h-7 w-full cursor-pointer rounded-[7px] disabled:cursor-default"
        style={{
          transform: "translate(0%, -50%)",
        }}
        {...getRailProps()}
      />

      <div
        className="pointer-events-none absolute h-1 w-full rounded bg-slate-300 dark:bg-slate-950"
        style={{
          transform: "translate(0%, -50%)",
        }}
      />
    </>
  );
}

interface IHandleProps {
  domain: [number, number];
  disabled: boolean;
  handle: SliderItem;
  getHandleProps: GetHandleProps;
  [key: string]: any;
}

function Handle({ domain, handle, disabled, getHandleProps, ...props }: IHandleProps) {
  const [min, max] = domain;

  return (
    <button
      type="button"
      role="slider"
      disabled={disabled}
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={handle.value}
      aria-orientation="horizontal"
      {...getHandleProps(handle.id)}
      {...props}
      style={{
        left: `${handle.percent}%`,
        transform: "translate(-50%, -50%)",
      }}
      className={classNames(
        "absolute h-4 w-4 cursor-pointer rounded-[50%] z-20 p-0 outline-none",
        "bg-white border-2 border-blue-600",
        "dark:bg-slate-500 dark:border-slate-800",
        "focus:shadow-md",
        "disabled:cursor-default",
      )}
    />
  );
}

interface TrackProps {
  source: SliderItem;
  target: SliderItem;
  getTrackProps: GetTrackProps;
  disabled: boolean;
}

function Track({ source, target, getTrackProps, disabled }: TrackProps) {
  return (
    <div
      aria-disabled={disabled}
      className="bg-blue-500 dark:bg-slate-500 absolute h-1 rounded-md cursor-pointer z-0"
      style={{
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
        transform: "translate(0%, -50%)",
      }}
      {...getTrackProps()}
    />
  );
}

interface TickProps {
  tick: {
    id: string;
    value: number;
    percent: number;
  };
  count: number;
  format: (value: number) => string;
}

function Tick({ tick, count, format }: TickProps) {
  return (
    <div>
      <div
        className="absolute mt-3 w-px"
        style={{
          height: "5px",
          backgroundColor: "rgb(200,200,200)",
          left: `${tick.percent}%`,
        }}
      />
      <div
        className="absolute mt-5 text-center font-aeonik text-[10px] text-xs text-gray-500"
        style={{
          marginLeft: `${-(100 / count) / 2}%`,
          width: `${100 / count}%`,
          left: `${tick.percent}%`,
        }}
      >
        {format(tick.value)}
      </div>
    </div>
  );
}
