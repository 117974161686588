import type { GetHandleProps, GetRailProps, GetTrackProps, SliderItem } from "react-compound-slider";
import { Handles, Rail, Slider, Tracks } from "react-compound-slider";
import classNames from "components/ui/classNames";
import palette from "../palette";

interface IValueSliderProps {
  domain: [number, number];
  values: ReadonlyArray<number>;
  labels?: [string, string];
  disabled?: boolean;
  onChange: (values: ReadonlyArray<number>) => void;
  onUpdate?: (value: ReadonlyArray<number>) => void;
}

export default function ValueSlider({
  domain,
  values,
  disabled = false,
  labels,
  onUpdate,
  onChange,
}: IValueSliderProps) {
  return (
    <Slider
      mode={1}
      step={1}
      domain={domain}
      values={values}
      rootStyle={{
        position: "relative",
        width: "100%",
      }}
      disabled={disabled}
      onChange={onChange}
      onUpdate={onUpdate}
    >
      <Rail>{({ getRailProps }) => <SliderRail disabled={disabled} getRailProps={getRailProps} />}</Rail>

      <Handles>
        {({ handles, getHandleProps }) => (
          <div>
            {handles.map((handle, index) => {
              const labelForHandle = Array.isArray(labels) ? labels[index] : null;

              return (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  disabled={disabled}
                  aria-label={labelForHandle}
                  getHandleProps={getHandleProps}
                />
              );
            })}
          </div>
        )}
      </Handles>

      <Tracks right={false}>
        {({ tracks, getTrackProps }) => (
          <div>
            {tracks.map(({ id, source, target }) => {
              return (
                <Track key={id} source={source} target={target} disabled={disabled} getTrackProps={getTrackProps} />
              );
            })}
          </div>
        )}
      </Tracks>
    </Slider>
  );
}

interface ISliderRail {
  disabled: boolean;
  getRailProps: GetRailProps;
}

function SliderRail({ disabled, getRailProps }: ISliderRail) {
  return (
    <>
      <div
        aria-disabled={disabled}
        className="absolute h-7 w-full cursor-pointer rounded-[7px] disabled:cursor-default"
        style={{
          transform: "translate(0%, -50%)",
        }}
        {...getRailProps()}
      />

      <div
        className="pointer-events-none absolute h-1 w-full rounded"
        style={{
          backgroundColor: palette.mystic,
          transform: "translate(0%, -50%)",
        }}
      />
    </>
  );
}

interface IHandleProps {
  domain: [number, number];
  disabled: boolean;
  handle: SliderItem;
  getHandleProps: GetHandleProps;
  [key: string]: any;
}

function Handle({ domain, handle, disabled, getHandleProps, ...props }: IHandleProps) {
  const [min, max] = domain;

  return (
    <button
      type="button"
      role="slider"
      disabled={disabled}
      aria-valuemin={min}
      aria-valuemax={max}
      aria-valuenow={handle.value}
      aria-orientation="horizontal"
      {...props}
      style={{
        left: `${handle.percent}%`,
        transform: "translate(-50%, -50%)",
        zIndex: 2,
      }}
      className={classNames(
        "absolute h-4 w-4 cursor-pointer rounded-[50%] bg-white",
        "border-2 p-0 outline-none",
        {
          "border-blue-600": !disabled,
          "border-gray-500": disabled,
        },
        {
          "hover:border-blue-500": !disabled,
          "hover:border-gray-500": disabled,
        },
        "focus:shadow-md",
        "disabled:cursor-default",
      )}
      {...getHandleProps(handle.id)}
    />
  );
}

interface ITrackProps {
  source: SliderItem;
  target: SliderItem;
  getTrackProps: GetTrackProps;
  disabled: boolean;
}

function Track({ source, target, getTrackProps, disabled }: ITrackProps) {
  return (
    <div
      aria-disabled={disabled}
      style={{
        position: "absolute",
        left: `${source.percent}%`,
        zIndex: 1,
        width: `${target.percent - source.percent}%`,
        height: 4,
        backgroundColor: disabled ? palette.mystic : palette.cornflowerBlue,
        borderRadius: 7,
        transform: "translate(0%, -50%)",
        cursor: disabled ? "default" : "pointer",
      }}
      {...getTrackProps()}
    />
  );
}
