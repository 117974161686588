import { useMutation } from "@apollo/client";
import { useState } from "react";

import {
  UserSettingsSchema,
  type UserSettingsOptionsKeys,
  type UserSettingsShape,
} from "components/settings/UserSettingsSchema";

import type {
  IChangeUserSettingMutation,
  IChangeUserSettingMutationVariables,
} from "lib/graphql/mutations/settings/__generated__/ChangeUserSettingMutation.generated";
import ChangeUserSettingMutation from "lib/graphql/mutations/settings/ChangeUserSettingMutation";
import useSelfie from "lib/hooks/useSelfie";

export default function useChangeSetting<
  Key extends UserSettingsOptionsKeys,
  Value extends UserSettingsShape[Key],
  DefaultValue extends UserSettingsShape[Key],
  ValueFn extends (currentSettings: UserSettingsShape[Key]) => UserSettingsShape[Key],
>(key: Key, defaultNotSet: DefaultValue | null = null) {
  const viewer = useSelfie();
  const [localSetting, setLocalSetting] = useState<Value | null>(null);
  const [mutate, { loading }] = useMutation<IChangeUserSettingMutation, IChangeUserSettingMutationVariables>(
    ChangeUserSettingMutation,
  );
  const setSettting = (valueOrValueProducerFn: Value | ValueFn) => {
    if (viewer) {
      const value =
        // @ts-ignore
        typeof valueOrValueProducerFn === "function" && viewer?.settingsV2[key]
          ? // @ts-ignore
            valueOrValueProducerFn(viewer?.settingsV2[key] as UserSettingsShape[Key])
          : valueOrValueProducerFn;
      return mutate({
        variables: {
          key,
          value,
        },
        optimisticResponse: {
          __typename: "Mutation",
          changeUserSetting: {
            ...viewer.settingsV2,
            [key]: value,
          },
        },
      });
    }

    if (typeof valueOrValueProducerFn === "function") {
      setLocalSetting(valueOrValueProducerFn(localSetting as UserSettingsShape[Key]) as unknown as Value);
      return null;
    }

    setLocalSetting(valueOrValueProducerFn);
    return null;
  };
  const resolvedSettings = UserSettingsSchema.partial().parse(viewer?.settingsV2 || {});
  const currentValue = resolvedSettings[key];
  return {
    value: (viewer ? currentValue : localSetting) ?? defaultNotSet,
    set: setSettting,
    loading,
  };
}
