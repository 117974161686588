import type { ReactElement } from "react";

interface INotificationActorsProps {
  maximumActorsConjunction: number;
  actors: ReactElement[];
}

export default function NotificationActors({ actors, maximumActorsConjunction }: INotificationActorsProps) {
  if (actors.length === 0) {
    return null;
  }

  if (actors.length === 1) {
    return actors[0];
  }

  const capped = actors.slice(0, maximumActorsConjunction);

  if (actors.length > maximumActorsConjunction) {
    const othersCount = actors.length - capped.length;

    if (othersCount === 1) {
      capped.push(<span key="1 outro">1 outro</span>);
    }

    if (othersCount >= 2) {
      const othersText = `${othersCount} outros`;
      capped.push(<span key={othersText}>{othersText}</span>);
    }
  }

  return capped.reduce((accumulator, actor, index, arrayOfActors) => {
    // tells if the current actor is the last one in the truncated array
    const currentIsLast = index === arrayOfActors.length - 1;

    // "Andrey e Eduardo" or "Andrey, Giovanni e Eduardo" if it's the last participant in the array
    if (arrayOfActors.length === 2 || currentIsLast) {
      return [accumulator, " e ", actor] as any;
    }

    // "Andrey, Giovanni"
    return [accumulator, ", ", actor];
  });
}
