import type { ReactElement } from "react";
import { useEffect, useState } from "react";

interface ITwoPassRenderProps {
  children: (document: Document) => ReactElement | null;
  loading?: () => ReactElement | null;
}

export default function TwoPassRender({ children, loading }: ITwoPassRenderProps) {
  const [isClient, setIsClient] = useState<boolean>(false);

  useEffect(() => {
    setIsClient(typeof document !== "undefined");
  }, []);

  if (isClient) {
    return children(document);
  }

  return loading ? loading() : null;
}
