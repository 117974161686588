import { FormattedMessage } from "react-intl";
import NotificationContentParagraph from "./elements/NotificationContentParagraph";

export default function NoNotifications() {
  return (
    <li className="p-4 text-center">
      <NotificationContentParagraph>
        <FormattedMessage
          defaultMessage="Você ainda não tem nenhuma notificação."
          id="6uBZej"
          description="Notificações"
        />
      </NotificationContentParagraph>
    </li>
  );
}
