interface ISafeActorNameProps {
  firstName: string;
}

export default function SafeActorName({ firstName }: ISafeActorNameProps) {
  return (
    <strong title={firstName} className="text-sm font-bold tracking-wider text-[#394e66] dark:text-slate-100">
      {safeFirstName(firstName)}
    </strong>
  );
}
function safeFirstName(firstName: string) {
  const firstNameInWords = firstName.split(" ").map((name) => name.trim());

  if (firstNameInWords.length === 0) {
    return "";
  }

  const firstWord = firstNameInWords[0];
  const truncated = firstWord.slice(0, 12);
  const ellipsis = firstWord.length > truncated.length;

  return ellipsis ? `${truncated}...` : truncated;
}
