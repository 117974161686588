import * as PopoverPrimitive from "@radix-ui/react-popover";
import { forwardRef } from "react";
import classNames from "components/ui/classNames";

const Popover = PopoverPrimitive.Root;

const PopoverContent = forwardRef<
  React.ElementRef<typeof PopoverPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, align = "center", sideOffset = 8, ...props }, ref) => {
  return (
    <PopoverPrimitive.Portal>
      <PopoverPrimitive.Content
        ref={ref}
        align={align}
        sideOffset={sideOffset}
        className={classNames(
          "z-50 box-border rounded-md border border-slate-200 bg-white p-0.5 shadow-md outline-none",
          "dark:border-slate-800 dark:bg-slate-800",
          className,
        )}
        {...props}
      />
    </PopoverPrimitive.Portal>
  );
});
PopoverContent.displayName = PopoverPrimitive.Content.displayName;

const PopoverTrigger = PopoverPrimitive.Trigger;
const PopoverAnchor = PopoverPrimitive.Anchor;

export { Popover, PopoverTrigger, PopoverAnchor, PopoverContent };
