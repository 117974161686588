import classNames from "components/ui/classNames";

interface INotificationsUnreadUnseenCountProps {
  unreadUnseenCount: number;
}

const FIRST_TIER_CAP = 99; // if the user has more than 99 notifications we'll cap the number to `SECOND_TIER_CAP`
const SECOND_TIER_CAP = 9;

export default function NotificationsUnreadUnseenCount({ unreadUnseenCount }: INotificationsUnreadUnseenCountProps) {
  const cappedUnreadUnseenCount = Math.min(Math.max(unreadUnseenCount, 0), FIRST_TIER_CAP);

  // TODO: ideally we'd spell the number here. It's better for screen readers. Currently if you have "123 notifications"
  // it would read: "um dois três notificações" instead of "cento e vinte três notificações".
  let ariaAccessibilityLabel = `${unreadUnseenCount} notificações`;

  if (unreadUnseenCount > cappedUnreadUnseenCount) {
    ariaAccessibilityLabel = `Mais de ${cappedUnreadUnseenCount} notificações`;
  } else if (cappedUnreadUnseenCount === 0) {
    ariaAccessibilityLabel = "Nenhuma notificação";
  } else {
    ariaAccessibilityLabel = `${cappedUnreadUnseenCount} notificações`;
  }

  return (
    <span
      aria-label={ariaAccessibilityLabel}
      className={classNames(
        "box-border inline-flex h-5 w-5 select-none appearance-none items-center justify-center",
        "rounded-md bg-red-500 pb-[1px] pl-[1px] text-xs font-bold text-white",
        "dark:bg-red-800 dark:text-white",
      )}
    >
      {unreadUnseenCount > cappedUnreadUnseenCount ? `${SECOND_TIER_CAP}+` : cappedUnreadUnseenCount}
    </span>
  );
}
