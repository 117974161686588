import Link from "next/link";
import * as React from "react";
import type {
  RepliedToPostActivity,
  ReactedToPostActivity,
  ReactedToPostReplyActivity,
  AlsoRepliedToPostActivity,
} from "../../types";

interface ILinkToPostProps extends React.HTMLAttributes<HTMLAnchorElement> {
  activity: RepliedToPostActivity | ReactedToPostActivity | ReactedToPostReplyActivity | AlsoRepliedToPostActivity;
  children: any;
}

export default function LinkToPost({ activity, children, ...props }: ILinkToPostProps) {
  if (activity && activity.refs) {
    let linkHref: string | null = "#";

    if (activity.refs.type === "BLACK_FORUM" || activity.refs.type === "IRPF_FORUM") {
      if (activity.refs.reply) {
        linkHref = `/forum/${activity.refs.post.shortId}/replies/${activity.refs.reply.shortId}`;
      } else {
        linkHref = `/forum/${activity.refs.post.shortId}`;
      }
    } else if (activity.refs.type === "BLACK_LESSON") {
      linkHref = `/black/courses/${activity.refs.course.slug}/${activity.refs.lesson.objectId}`;
    }

    return (
      <Link href={linkHref} className="text-inherit no-underline" {...props}>
        {children}
      </Link>
    );
  }

  return children;
}
