import { useTheme as useNextTheme } from "next-themes";
export type ThemeOptions = "dark" | "light";

export default function useTheme(): "dark" | "light" {
  const { theme, forcedTheme } = useNextTheme();
  if (forcedTheme) {
    return forcedTheme as "dark" | "light";
  }
  return (theme || "light") as "dark" | "light";
}
