import Auth from "components/auth/Auth";
import checkCanSkipToBlackCheckout from "components/black/checkCanSkipToBlackCheckout";
import Avatar from "components/core/Avatar";
import LionIcon from "components/icons/LionIcon";
import MobileNavbarLink from "components/navbar/MobileNavbarLink";
import MobileNavbarMenuItem from "components/navbar/MobileNavbarMenuItem";
import classNames from "components/ui/classNames";
import { useAmplitude } from "lib/amplitude/Amplitude";
import useSelfie from "lib/hooks/useSelfie";
import {
  BookmarkIcon,
  CalendarIcon,
  CoinsIcon,
  DoorOpenIcon,
  GraduationCapIcon,
  LifeBuoyIcon,
  MegaphoneIcon,
  MessageCircleIcon,
  PercentIcon,
  PresentationIcon,
  RocketIcon,
  ScaleIcon,
  StarIcon,
  WalletIcon,
} from "lucide-react";
import Link from "next/link";
import { FormattedMessage } from "react-intl";

interface MobileNavbarNavProps {
  isDark?: boolean;
}

export default function MobileNavbarNav({ isDark }: MobileNavbarNavProps) {
  const viewer = useSelfie();
  const amplitude = useAmplitude();
  return (
    <nav
      className={classNames("flex h-max flex-col divide-y divide-slate-200 bg-white font-normal", {
        "divide-slate-800 bg-slate-950": isDark,
      })}
    >
      {!viewer && (
        <MobileNavbarMenuItem
          href="/login"
          title="Entrar"
          description="Faça Login ou Cadastre-se para ter acesso completo."
          icon={<DoorOpenIcon className="h-5 w-5 fill-transparent" />}
          iconClassName="border-teal-600/20 dark:border-teal-200 bg-teal-50 text-teal-400"
          titleClassName="text-teal-600 dark:text-teal-200"
          variant={isDark ? "dark" : "light"}
        />
      )}

      {!viewer?.isPremium && (
        <MobileNavbarLink href="/premium" className="bg-indigo-600/10">
          <div className="flex items-center justify-between space-x-6">
            <div className="space-y-1">
              <span className="font-bold text-brand dark:text-indigo-300">
                <FormattedMessage defaultMessage="Seja Premium" id="TvfPzl" description="Item da barra de navegação" />
              </span>
              <p className="text-xs tracking-wider text-slate-500 dark:text-slate-400">
                <FormattedMessage
                  defaultMessage="Seja Premium e turbine suas análises com dados exclusivos de mais de 6.000 ativos."
                  id="AeWhK1"
                  description="Item da barra de navegação"
                />
              </p>
            </div>

            <div className="z-0 flex h-10 w-10 items-center justify-center rounded-full bg-brand p-1 shadow-sm">
              <div className="inline-flex rounded-full bg-brand p-1.5 text-white">
                <RocketIcon className="h-5 w-5 fill-transparent" />
              </div>
            </div>
          </div>
        </MobileNavbarLink>
      )}

      {viewer && checkCanSkipToBlackCheckout(viewer) && (
        <MobileNavbarLink
          href="https://hotm.art/HqMWAP0"
          className="bg-slate-100 dark:bg-slate-500/20"
          target="_blank"
          onClick={() => {
            amplitude.logEvent("Click on link to become Black", {
              origin: "Seja Black - Mobile Navbar",
            });
          }}
        >
          <div className="flex items-center justify-between space-x-6">
            <div className="space-y-1">
              <span className="font-bold text-black dark:text-stone-300">
                <FormattedMessage defaultMessage="Seja Black" id="C9Ior4" description="Item da barra de navegação" />
              </span>
              <p className="text-xs tracking-wider text-slate-500 dark:text-slate-400">
                <FormattedMessage
                  defaultMessage="Seja Black para ter acesso a toda nossa biblioteca de cursos."
                  id="Ry3d1O"
                  description="Item da barra de navegação"
                />
              </p>
            </div>

            <div className="z-0 flex h-10 w-10 items-center justify-center rounded-full bg-black p-1 shadow-sm">
              <div className="inline-flex rounded-full bg-black p-1.5 text-white">
                <GraduationCapIcon className="h-5 w-5 fill-transparent" />
              </div>
            </div>
          </div>
        </MobileNavbarLink>
      )}

      {viewer && (viewer.isSiteAdmin || viewer.isBlack) && (
        <>
          <MobileNavbarMenuItem
            href="/black/courses"
            title="Cursos • Black"
            description="Acesse todo o conteúdo disponível e tire suas dúvidas com os mentores Eduardo e Asvid!"
            icon={<PresentationIcon className="h-5 w-5 fill-transparent" />}
            iconClassName="border-blue-200 bg-blue-50 text-blue-400"
            variant={isDark ? "dark" : "light"}
          />

          <MobileNavbarMenuItem
            href="/black/forum"
            title="Comunidade • Black"
            description="Crie um tópico e tire suas dúvidas com os mentores Eduardo e Asvid, além de outros Blacks!"
            icon={<MessageCircleIcon className="h-5 w-5 fill-transparent" />}
            iconClassName="border-blue-200 bg-blue-50 text-blue-400"
            variant={isDark ? "dark" : "light"}
          />
        </>
      )}

      <MobileNavbarMenuItem
        href="/portfolio"
        title="Minha Carteira"
        description="Seus ativos da Bolsa de Valores em um só lugar."
        icon={<WalletIcon className="h-5 w-5 fill-transparent" />}
        iconClassName="border-teal-200 bg-teal-50 text-teal-400"
        variant={isDark ? "dark" : "light"}
      />

      <MobileNavbarMenuItem
        href="/bookmarks"
        title="Favoritos"
        description="Sua área exclusiva com todos os ativos que você salvou para estudar."
        icon={<BookmarkIcon className="h-5 w-5 fill-transparent" />}
        iconClassName="border-orange-200 bg-orange-50 text-orange-400"
        variant={isDark ? "dark" : "light"}
      />

      <MobileNavbarMenuItem
        href="/compare"
        title="Comparativo"
        description="Compare até 5 ativos utilizando mais de 900 indicadores entre Ações, FIIs, Stocks e REITs."
        icon={<ScaleIcon className="h-5 w-5 fill-transparent" />}
        iconClassName="border-orange-200 bg-orange-50 text-orange-400"
        variant={isDark ? "dark" : "light"}
      />

      <MobileNavbarMenuItem
        href="/financials"
        title="Balanços"
        description="Analise aqui apenas os balanços atualizados de mais de 6.000 Stocks, REITs, FIIs e Ações."
        icon={<CoinsIcon className="h-5 w-5 fill-transparent" />}
        iconClassName="border-cyan-200 bg-cyan-50 text-cyan-400"
        variant={isDark ? "dark" : "light"}
      />

      <MobileNavbarMenuItem
        href="/events-calendar"
        title="Agenda"
        description="Confira agora Agenda de Balanços mais completa do Brasil."
        icon={<CalendarIcon className="h-5 w-5 fill-transparent" />}
        iconClassName="border-blue-200 bg-blue-50 text-blue-400"
        variant={isDark ? "dark" : "light"}
      />

      <MobileNavbarMenuItem
        href="/discussions"
        title="Discussões"
        description="Fique por dentro dos últimos comentários e participe das discussões."
        icon={<MessageCircleIcon className="h-5 w-5 fill-transparent" />}
        iconClassName="border-violet-200 bg-violet-50 text-violet-400"
        variant={isDark ? "dark" : "light"}
      />

      <MobileNavbarMenuItem
        href="/fatos-relevantes"
        title="Fatos Relevantes"
        description="Confira os Fatos Relevantes das empresas divulgados nos últimos 90 dias."
        icon={<MegaphoneIcon className="h-5 w-5 fill-transparent" />}
        iconClassName="border-emerald-200 bg-emerald-50 text-emerald-400"
        variant={isDark ? "dark" : "light"}
      />

      <MobileNavbarMenuItem
        href="/ranking"
        title="Ranking"
        description="Quais são as melhores Ações, FIIs, Stocks e REITs? Acesse aqui o Ranking completo."
        icon={<StarIcon className="h-5 w-5 fill-transparent" />}
        iconClassName="border-yellow-200 bg-yellow-50 text-yellow-400"
        variant={isDark ? "dark" : "light"}
      />

      <MobileNavbarMenuItem
        href="/coupons"
        title="Descontos"
        icon={<PercentIcon className="h-5 w-5 fill-transparent" />}
        iconClassName="border-yellow-200 bg-yellow-50 text-yellow-400"
        variant={isDark ? "dark" : "light"}
      />

      <MobileNavbarMenuItem
        href="/irpf"
        title="IRPF"
        icon={<LionIcon className="h-5 w-5 fill-transparent" fill="#3B82F6" />}
        iconClassName="border-blue-200 bg-blue-50 text-blue-400"
        variant={isDark ? "dark" : "light"}
      />

      <MobileNavbarMenuItem
        href="/support"
        title="Suporte"
        icon={<LifeBuoyIcon className="h-5 w-5 fill-transparent" />}
        iconClassName="border-blue-200 bg-blue-50 text-blue-400"
        variant={isDark ? "dark" : "light"}
      />

      {viewer && (
        <Link as="/settings" href="/settings" passHref={true}>
          <div className="flex items-center justify-between space-x-6 bg-gray-50 p-4 dark:bg-slate-950">
            <div className="space-y-1">
              <span className="font-bold text-zinc-700 dark:text-white">{viewer.fullName}</span>
              <p className="text-xs text-zinc-500 dark:text-zinc-300">{viewer.email}</p>
            </div>
            <Avatar
              variant="rounded"
              squircle={true}
              src={viewer.pictureUrl}
              hashValue={viewer._id}
              alt={viewer.fullName}
              size={38}
            />
          </div>
        </Link>
      )}

      {viewer && (
        <Auth>
          {({ logout }) => (
            <button
              type="button"
              onClick={() => logout()}
              className={classNames(
                "font-aeonik",
                "px-4 py-4 text-left",
                "text-sm tracking-wide text-zinc-600 hover:text-gray-400 dark:text-red-400 dark:hover:text-red-300",
                "hover:bg-slate-100 focus:outline-none dark:hover:bg-slate-900",
                "font-medium",
              )}
            >
              <FormattedMessage defaultMessage="Sair" id="LNUOti" description="Item da barra de navegação" />
            </button>
          )}
        </Auth>
      )}
    </nav>
  );
}
