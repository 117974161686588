import { IPlatformVertical } from "types/graphqlTypes";

interface IStandardIssuerObject {
  objectID: string;
  _id: string;
  type: "AMERICAN_COMPANY" | "AMERICAN_REIT" | "BRAZILIAN_COMPANY" | "BRAZILIAN_REAL_ESTATE_FUND";
  name: string;
  tickerSymbolPrefix: string;
  tickerSymbols: string[];
  logoUrl: string | null;
  description: string;
  headquarters: {
    countryName: string;
    countryCode: string;
  } | null;
  industrySector: string;
  industrySegment: string;
  marketCapitalization: number;
  viewerDidBookmark: boolean;
  isInPortfolio: boolean;
  ratingSummary?: {
    periodStartDate: string;
    periodEndDate: string;
    overallRating: number;
    totalRatings: number;
    distribution: {
      rating: number;
      totalRatings: number;
      percentOfTotal: number;
    }[];
  };
  nareitClassification?: {
    propertySector: string;
    propertySubsector: string | null;
    propertySectorAndSubsector: string;
  };
}

export interface IAmericanCompanyIssuerObject extends IStandardIssuerObject {
  type: "AMERICAN_COMPANY";
}

export interface IAmericanReitIssuerObject extends IStandardIssuerObject {
  type: "AMERICAN_REIT";
}

export interface IBrazilianCompanyIssuerObject extends IStandardIssuerObject {
  type: "BRAZILIAN_COMPANY";
}

export interface IBrazilianRealEstateFundIssuerObject extends IStandardIssuerObject {
  type: "BRAZILIAN_REAL_ESTATE_FUND";
  administrator: string | null;
}

export type IIssuerObject =
  | IAmericanCompanyIssuerObject
  | IAmericanReitIssuerObject
  | IBrazilianCompanyIssuerObject
  | IBrazilianRealEstateFundIssuerObject;

export function getPlatformVerticalFromIssuerObject(hit: IIssuerObject): string {
  if (hit.type === "AMERICAN_COMPANY") {
    return IPlatformVertical.Stocks;
  }
  if (hit.type === "AMERICAN_REIT") {
    return IPlatformVertical.Reits;
  }
  if (hit.type === "BRAZILIAN_COMPANY") {
    return IPlatformVertical.Acoes;
  }
  return IPlatformVertical.Fiis;
}
