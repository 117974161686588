import * as React from "react";
import classNames from "components/ui/classNames";

export type TMiniSearchInputHeight = 28 | 32 | 36 | 40 | 44 | 48;

type InputPropsWithoutOnChange = Omit<
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  "onChange"
>;

interface MiniSearchInputProps extends InputPropsWithoutOnChange {
  height?: TMiniSearchInputHeight;
  value: string;
  onChange: (value: string) => void;
}

export default function MiniSearchInput({ height = 36, value, onChange, style, ...props }: MiniSearchInputProps) {
  return (
    <div className="relative w-full">
      <input
        aria-autocomplete="none"
        autoComplete="off"
        autoCorrect="off"
        maxLength={126}
        onChange={(event) => onChange(event.currentTarget.value)}
        spellCheck={false}
        value={value}
        className={classNames(
          "font-aeonik",
          "m-0 p-0",
          "text-sm font-normal",
          "box-border flex w-full items-center rounded-lg",
          "border border-slate-300 bg-white shadow-sm",
          "dark:bg-slate-900 dark:border-slate-950",
        )}
        style={{
          height,
          transition: "all 60ms ease 0s",
          WebkitAppearance: "none",
          ...getPaddingForHeight(height),
          ...style,
        }}
        {...props}
      />

      {value ? (
        <div
          role="button"
          aria-label="Limpar"
          tabIndex={0}
          onClick={() => {
            if (value !== "") {
              onChange("");
            }
          }}
          onKeyPress={(event) => {
            if (value === "") {
              return;
            }

            if (event.key === "Enter" || event.key === " ") {
              onChange("");
            }
          }}
          className={classNames(
            "absolute z-[2] inline-flex h-4 w-4 cursor-pointer items-center justify-center text-[#829ab1]",
            "hover:text-red-700 focus:outline-none",
          )}
          style={{
            top: "50%",
            right: 14,
            transform: "translateY(-50%)",
          }}
        >
          <ClearIcon width={14} height={14} />
        </div>
      ) : (
        <div
          aria-hidden={true}
          className="absolute z-[2] inline-flex h-4 w-4 cursor-pointer items-center justify-center"
          style={{
            top: "50%",
            right: 14,
            transform: "translateY(-50%)",
            pointerEvents: value === "" ? "none" : "all",
          }}
        >
          <SearchIcon />
        </div>
      )}
    </div>
  );
}

function SearchIcon(props: React.SVGProps<any>) {
  return (
    <svg width={16} height={16} {...props}>
      <g
        transform="translate(1 1)"
        stroke="#9FB3C8"
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx={6.222} cy={6.222} r={6.222} />
        <path d="M14 14l-3.383-3.383" />
      </g>
    </svg>
  );
}

function ClearIcon(props: React.SVGProps<any>) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 14 14" {...props}>
      <path
        d="M8.164 7l5.595-5.594A.823.823 0 1012.594.24L7 5.836 1.406.24A.823.823 0 10.24 1.406L5.836 7 .24 12.594a.823.823 0 101.165 1.165L7 8.165l5.594 5.594a.821.821 0 001.164 0 .823.823 0 000-1.165L8.164 7z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
}

function getPaddingForHeight(height: TMiniSearchInputHeight) {
  if (height <= 40) {
    return {
      paddingRight: 36,
      paddingLeft: 12,
    };
  }

  return {
    paddingRight: 40,
    paddingLeft: 12,
  };
}
