import type { ReactElement } from "react";
import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";

import type { AnyOtherNotification, BaseNotification } from "components/engagement/notifications/types";
import UnknownNotificationListItem from "components/engagement/notifications/items/UnknownNotificationListItem";
import ReactedToCommentOrReplyNotificationListItem from "components/engagement/notifications/items/ReactedToCommentOrReplyNotificationListItem";
import RepliedToPostNotificationListItem from "components/engagement/notifications/items/RepliedToPostNotificationListItem";
import ReactedToPostOrPostReplyNotificationListItem from "components/engagement/notifications/items/ReactedToPostOrPostReplyNotificationListItem";
import AlsoRepliedToPostNotificationListItem from "components/engagement/notifications/items/AlsoRepliedToPostNotificationListItem";

import { palette } from "lib/miolo";

import { FormattedMessage } from "react-intl";
import { useAmplitude } from "../../../lib/amplitude/Amplitude";
import type { ISelfieQuery } from "../../../lib/queries/__generated__/SelfieQuery.generated";

import AlsoRepliedToCommentNotificationListItem from "./items/AlsoRepliedToCommentNotificationListItem";
import RepliedToCommentNotificationListItem from "./items/RepliedToCommentNotificationListItem";
import LoadMoreNotificationsButton from "./LoadMoreNotificationsButton";
import NoNotifications from "./NoNotifications";
import NotificationsFeedStream from "./NotificationsFeedStream";
import NotificationsFlyout from "./NotificationsFlyout";

interface INotificationsProps {
  viewer: NonNullable<ISelfieQuery["selfie"]>;
}

export default function Notifications({ viewer }: INotificationsProps) {
  const amplitude = useAmplitude();

  return (
    <NotificationsFeedStream
      limit={18}
      limitPerPage={9}
      doNotRequest={viewer.isPremium === false}
      appId={viewer.settings.stream.appId || process.env.STREAM_APP_KEY || ""}
      appKey={viewer.settings.stream.appKey || process.env.STREAM_APP_ID || ""}
      location={viewer.settings.stream.region}
      userToken={viewer.settings.stream.userToken}
      userId={viewer._id}
    >
      {({
        loading,
        totalUnread,
        notifications,
        hasNextPage,
        loadingMore,
        loadMoreNotifications,
        markSingleAsRead,
        markThoseAsRead,
        markAllAsRead,
      }) => (
        <div>
          <NotificationsFlyout
            disabled={loading}
            unreadUnseenCount={totalUnread}
            markAllAsRead={() => {
              if (amplitude) {
                amplitude.logEvent("Marked all notifications as read", {
                  totalUnread,
                });
              }

              markAllAsRead();
            }}
            onOpen={() => {
              if (amplitude) {
                amplitude.logEvent("Opened notifications flyout", {
                  totalUnread,
                });
              }

              // smart "Mark as read" - if up to 4 first notifications are unread/unseen then whenever the user opens
              // the flyout we can mark them as read automatically
              if (totalUnread <= 4) {
                const unreadStreak = notifications.slice(0, 4).reduce((streak, notification) => {
                  return notification.is_read ? 0 : streak + 1;
                }, 0);

                if (unreadStreak === 0) {
                  return;
                }

                const unreadSlice = notifications.slice(0, 4).map((notification) => {
                  return notification.id;
                });

                markThoseAsRead(unreadSlice);
              }
            }}
          >
            {({ onCloseFlyout }) => (
              <>
                <ul className="m-0 list-none p-0">
                  {notifications.length === 0 && <NoNotifications />}

                  {notifications.map((notification) => {
                    const defaultProps: React.HTMLAttributes<HTMLLIElement> = {
                      onClick: (event) => {
                        const metaShiftCtrlOrAltKey = event.metaKey || event.shiftKey || event.altKey || event.ctrlKey;

                        amplitude.logEvent("Interacted with a notification", {
                          totalUnread,
                          metaShiftCtrlOrAltKey,
                          verb: notification.verb,
                          actorCount: notification.actor_count,
                          activityCount: notification.activity_count,
                        });

                        // if the user has clicked in the notification to be opened in a new tab...let's say/consider that
                        // he may not want to mark it as read yet.
                        if (metaShiftCtrlOrAltKey) {
                          return;
                        }

                        markSingleAsRead(notification.id);
                        onCloseFlyout();
                      },
                    };

                    if (notification.verb === "repliedToComment") {
                      return (
                        <NotificationErrorBoundary key={notification.id} notification={notification}>
                          <RepliedToCommentNotificationListItem {...defaultProps} notification={notification} />
                        </NotificationErrorBoundary>
                      );
                    }

                    if (notification.verb === "alsoRepliedToComment") {
                      return (
                        <NotificationErrorBoundary key={notification.id} notification={notification}>
                          <AlsoRepliedToCommentNotificationListItem {...defaultProps} notification={notification} />
                        </NotificationErrorBoundary>
                      );
                    }

                    if (notification.verb === "repliedToPost") {
                      return (
                        <NotificationErrorBoundary key={notification.id} notification={notification}>
                          <RepliedToPostNotificationListItem {...defaultProps} notification={notification} />
                        </NotificationErrorBoundary>
                      );
                    }

                    if (notification.verb === "alsoRepliedToPost") {
                      return (
                        <NotificationErrorBoundary key={notification.id} notification={notification}>
                          <AlsoRepliedToPostNotificationListItem {...defaultProps} notification={notification} />
                        </NotificationErrorBoundary>
                      );
                    }

                    if (notification.verb === "reactedToComment" || notification.verb === "reactedToReply") {
                      return (
                        <NotificationErrorBoundary key={notification.id} notification={notification}>
                          <ReactedToCommentOrReplyNotificationListItem {...defaultProps} notification={notification} />
                        </NotificationErrorBoundary>
                      );
                    }

                    if (notification.verb === "reactedToPost" || notification.verb === "reactedToPostReply") {
                      return (
                        <NotificationErrorBoundary key={notification.id} notification={notification}>
                          <ReactedToPostOrPostReplyNotificationListItem {...defaultProps} notification={notification} />
                        </NotificationErrorBoundary>
                      );
                    }

                    return (
                      <UnknownNotificationListItem
                        key={notification.id}
                        notification={notification as AnyOtherNotification}
                      />
                    );
                  })}
                </ul>

                {hasNextPage && (
                  <div className="px-0 py-4 text-center">
                    <LoadMoreNotificationsButton
                      loading={loadingMore}
                      disabled={loadingMore}
                      onClick={() => {
                        amplitude.logEvent("Load more notifications", {
                          totalUnread,
                        });

                        loadMoreNotifications();
                      }}
                    />
                  </div>
                )}
              </>
            )}
          </NotificationsFlyout>
        </div>
      )}
    </NotificationsFeedStream>
  );
}

interface INotificationErrorBoundaryProps {
  children: ReactElement;
  notification: BaseNotification;
}

function NotificationErrorBoundary({ notification, children, ...props }: INotificationErrorBoundaryProps) {
  return (
    <ErrorBoundary
      // eslint-disable-next-line react/no-unstable-nested-components
      FallbackComponent={() => {
        return (
          <li
            className="box-border grid grid-cols-1 gap-x-3 px-4 py-3"
            style={{
              borderLeft: notification.is_read ? "solid 2px transparent" : `solid 2px ${palette.mariner}`,
            }}
            {...props}
          >
            <FormattedMessage
              defaultMessage="Não foi possível carregar essa notificação"
              id="ad12gU"
              description="Notificações"
            />
          </li>
        );
      }}
    >
      {children}
    </ErrorBoundary>
  );
}
