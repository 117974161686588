export const bigStone = "#172a41";
export const catskillWhite = "#f1f4f8";
export const pickledBluewood = "#293b51";
export const fiord = "#394e66";
export const blueBayoux = "#4e657f";
export const lynch = "#627d98";
export const baliHai = "#829ab1";
export const rockBlue = "#9fb3c8";
export const heather = "#bfccda";
export const mystic = "#dbe2eb";
export const biscay = "#202c65";
export const bayOfMany = "#233586";
export const persianBlue = "#213cb5";
export const tundora = "#404040";
export const mineShaft = "#333333";
export const royalBlue = "#3f5fe9";
export const cornflowerBlue = "#627bea";
export const mariner = "#2f4eda";
export const perano = "#a4b2f4";
export const portage = "#8195ee";
export const tropicalBlue = "#d1d9fa";
export const selago = "#edf0fd";
export const tePapaGreen = "#1f4136";
export const stromboli = "#2d5d4e";
export const amazon = "#37725f";
export const viridian = "#39896f";
export const oceanGreen = "#3ea383";
export const keppel = "#46b995";
export const magicMint = "#b6f1de";
export const downy = "#6dd0b0";
export const riptide = "#95e9cf";
export const whiteIce = "#e4fbf4";
export const espresso = "#591818";
export const crownOfThorns = "#751f1f";
export const mojo = "#c74343";
export const burntUmber = "#992929";
export const chesnutRose = "#ce5a5a";
export const stiletto = "#a53131";
export const newYorkPink = "#d47373";
export const tonysPink = "#e58f8f";
export const shilo = "#e3a5a5";
export const vanillaIce = "#f6dfdf";
export const peanut = "#813218";
export const paarl = "#a75325";
export const dixie = "#de911d";
export const copper = "#be7230";
export const fuelYellow = "#f0b429";
export const casablanca = "#f7c948";
export const sweetCorn = "#fce588";
export const punga = "#56461a";
export const butteryWhite = "#fffbea";
export const energyYellow = "#fadb5f";
export const eggWhite = "#fff3c4";
export const kumera = "#7e6626";
export const luxorGold = "#997d2e";
export const tussock = "#bc9938";
export const roti = "#cbab52";
export const zombie = "#e1cd99";
export const calico = "#dbc485";
export const chamois = "#ecd9a7";
export const linen = "#faf4e5";
export const tacha = "#d2b76a";
export const link = "#1264a3";
export const linkVisited = "#603fa7";
export const white = "#ffffff";
export const zuccini = "#05400a";
export const darkFern = "#0e5814";
export const forestGreen = "#207227";
export const seaGreen = "#2f8132";
export const goblin = "#3f9142";
export const deYork = "#7bc47f";
export const fruitSalad = "#57ae5b";
export const mossGreen = "#a3d9a5";
export const fringyFlower = "#c1eac5";
export const grannyApple = "#e3f9e5";
export const downriver = "#092256";
export const chathamsBlue = "#123478";
export const funBlue = "#1b4598";
export const cobalt = "#0352b5";
export const scienceBlue = "#0767d2";
export const curiousBlue = "#2086eb";
export const pictonBlue = "#47a3f3";
export const malibu = "#7cc4fa";
export const frenchPass = "#bae3ff";
export const lilyWhite = "#e6f6ff";
