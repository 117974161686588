/* eslint-disable react/no-unstable-nested-components */
import useChangeSetting from "components/settings/hooks/useChangeSetting";
import classNames from "components/ui/classNames";
import useSelfie from "lib/hooks/useSelfie";
import { MoonIcon, SunIcon } from "lucide-react";
import { parseAsStringLiteral, useQueryState } from "nuqs";

import type { HTMLAttributes } from "react";

interface DarkModeButtonProps extends HTMLAttributes<HTMLButtonElement> {
  persistency?: "queryString" | "userSettings";
}

export default function DarkModeButton({ persistency = "userSettings", ...props }: DarkModeButtonProps) {
  const { value: currentThemeFromSettings, set: setThemeSetting } = useChangeSetting("theme", "LIGHT");
  const [currentThemeFromUrl, setThemeViaUrl] = useQueryState(
    "theme",
    parseAsStringLiteral(["light", "dark"]).withDefault("light"),
  );
  const viewer = useSelfie();

  const currentThemeOption = persistency === "userSettings" ? currentThemeFromSettings : currentThemeFromUrl;
  const theme = currentThemeOption?.toLowerCase() as "dark" | "light";

  return (
    <button
      {...props}
      disabled={false}
      type="button"
      onClick={() => {
        if (persistency === "userSettings") {
          const nextTheme = currentThemeFromSettings === "DARK" ? "LIGHT" : "DARK";
          setThemeSetting(nextTheme);
        }
        if (persistency === "queryString") {
          const nextTheme = currentThemeFromUrl === "dark" ? "light" : "dark";
          setThemeViaUrl(nextTheme === "light" ? null : nextTheme);
        }
      }}
      className={classNames(
        "flex h-10 w-10 items-center justify-center rounded-xl bg-neutral-100 text-gray-700 dark:bg-slate-700 dark:text-slate-300",
        "border border-transparent",
        "outline-none",
        "focus-visible:bg-indigo-50 focus-visible:ring-2 focus-visible:ring-indigo-100 focus-visible:ring-offset-1 focus-visible:ring-offset-indigo-300",
        "hover:bg-gray-50 hover:ring-2 hover:ring-gray-100 hover:ring-offset-1 hover:ring-offset-gray-300",
        "dark:hover:ring-slate-500 dark:hover:ring-offset-slate-500",
        {
          "bg-neutral-700 text-neutral-200 hover:bg-neutral-500": viewer && viewer.isBlack,
        },
      )}
    >
      {theme === "dark" ? <SunIcon strokeWidth={2} /> : <MoonIcon strokeWidth={2} />}
    </button>
  );
}
