import NotificationsUnreadUnseenCount from "components/engagement/notifications/NotificationsUnreadUnseenCount";
import NotificationsUnreadUnseenCountPositioner from "components/engagement/notifications/NotificationsUnreadUnseenCountPositioner";
import classNames from "components/ui/classNames";
import useSelfie from "lib/hooks/useSelfie";
import { BellIcon } from "lucide-react";
import * as React from "react";

interface NotificationsFlyoutTriggerProps {
  unreadUnseenCount: number;
  isOpen: boolean;
  disabled: boolean;
}

function NotificationsFlyoutTrigger({ unreadUnseenCount, isOpen, disabled }: NotificationsFlyoutTriggerProps) {
  const viewer = useSelfie();

  return (
    <div
      className={classNames(
        "relative",
        "flex h-10 w-10 items-center justify-center rounded-xl",
        "bg-neutral-100 text-gray-700 hover:text-gray-500",
        "border border-transparent",
        "dark:bg-slate-800 dark:text-slate-200 dark:hover:bg-slate-700 dark:hover:text-gray-300",
        {
          "border-rose-600 bg-rose-50 text-red-600 hover:text-red-400": unreadUnseenCount > 0,
          "border-purple-200 bg-purple-50 text-purple-600 hover:text-purple-600": isOpen,
          "pointer-events-none": disabled,
          "bg-neutral-700 text-neutral-200 hover:bg-neutral-500 hover:text-gray-300": viewer && viewer.isBlack,
          "bg-rose-600/20 text-red-600 hover:text-red-400 dark:border-transparent dark:text-rose-300 dark:hover:bg-rose-600/30":
            unreadUnseenCount > 0,
          "dark:border-transparent dark:bg-slate-900": isOpen,
        },
      )}
    >
      <BellIcon className="size-5" />
      {unreadUnseenCount > 0 && !isOpen && (
        <NotificationsUnreadUnseenCountPositioner>
          <NotificationsUnreadUnseenCount unreadUnseenCount={unreadUnseenCount} />
        </NotificationsUnreadUnseenCountPositioner>
      )}
    </div>
  );
}

export default NotificationsFlyoutTrigger;
