import { cva } from "class-variance-authority";
import classNames from "components/ui/classNames";
import * as React from "react";

interface LinkButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  className?: string;
  [key: string]: any;
}

const linkButtonVariants = cva(
  classNames(
    "pointer-events-auto m-0 box-border inline-block cursor-pointer",
    "select-none overflow-visible rounded-[1px] border-none bg-transparent p-0 text-sm",
    "transition-all duration-75 ease-in-out",
    "disabled:pointer-events-none",
    "tracking-wider",
    "font-medium dark:hover:text-white dark:hover:no-underline",
    "active:shadow-transparent",
  ),
  {
    variants: {
      variant: {
        primary: "text-blue-700 dark:text-blue-300 hover:underline",
        success: "text-[#39896f]",
        danger: "text-red-700 dark:text-red-300",
        warning: "text-[#de911d]",
        black: "text-[#333333]",
        disabled: "text-[#829ab1]",
      },
    },
    defaultVariants: {
      variant: "primary",
    },
  },
);

const LinkButton = React.forwardRef<HTMLButtonElement, LinkButtonProps>(
  ({ variant, children, disabled, className, ...props }, ref) => {
    return (
      <button
        ref={ref}
        type="button"
        disabled={disabled}
        aria-disabled={disabled}
        className={classNames(linkButtonVariants({ variant }), className)}
        {...props}
      >
        {children}
      </button>
    );
  },
);

export default LinkButton;
