import type { AnyOtherNotification } from "components/engagement/notifications/types";
import * as React from "react";
import { Hover } from "react-powerplug";
import { FormattedMessage } from "react-intl";
import hexColorToRgba from "../../../../lib/utils/hexColorToRgba";
import Avatar from "../../../core/Avatar";
import CommentTime from "../../../discussions/CommentTime";
import * as palette from "../../../palette";
import NotificationContentParagraph from "../elements/NotificationContentParagraph";
import getActivityTimeAsUtc from "../utils/getActivityTimeAsUtc";

interface IUnknownNotificationListItemProps extends React.HTMLAttributes<HTMLLIElement> {
  notification: AnyOtherNotification;
}

export default function UnknownNotificationListItem({ notification, ...props }: IUnknownNotificationListItemProps) {
  // Shouldn't happen but just making sure so it doesn't break when people are using it
  if (notification.activities.length === 0) {
    return null;
  }

  const topActivity = notification.activities[0];

  return (
    <Hover>
      {({ hovered, bind }) => (
        <li
          className="box-border grid grid-cols-[42px_1fr] gap-x-3 px-4 py-3 hover:bg-purple-50/80"
          style={{
            borderLeft: notification.is_read ? "solid 2px transparent" : `solid 2px ${palette.mariner}`,
            backgroundColor: hovered ? hexColorToRgba(palette.selago, 0.5) : undefined,
          }}
          {...bind}
          {...props}
        >
          <Avatar
            src={topActivity.actor.data.pictureUrl}
            hashValue={topActivity.actor.id}
            alt={topActivity.actor.data.firstName}
            size={42}
            squircle={true}
          />

          <div className="grid gap-y-2">
            <NotificationContentParagraph>
              <FormattedMessage
                defaultMessage="Não foi possível renderizar essa notificação :("
                id="NKNSXi"
                description="Notificações"
              />
            </NotificationContentParagraph>

            <div className="text-right">
              <CommentTime timeStamp={getActivityTimeAsUtc(notification.activities[0].time)} />
            </div>
          </div>
        </li>
      )}
    </Hover>
  );
}
